import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    avatar: {
        h: 9,
        w: 9,
        mr: 4,

        "& > div": {
            ...typography(16, 19, 500),
        },
    },
    name: {
        ...typography(14, 17, 500),
        color: "gray.500",

        "& > span": {
            fontWeight: 700,
        },
    },
    greeting: {
        ...typography(16, 19, 700),
        color: "blue.900",
        mt: "2px",
    },
};
