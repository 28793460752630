import { createIcon } from "@chakra-ui/react";

export const LogoutIcon = createIcon({
    displayName: "LogoutIcon",
    viewBox: "0 0 16 16",
    defaultProps: { fill: "none" },
    path: [
        <path
            d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />,
        <path
            d="M10.667 11.3334L14.0003 8.00002L10.667 4.66669"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />,
        <path d="M14 8H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />,
    ],
});
