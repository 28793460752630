import { ArticleType } from "containers/Admin/ArticlesPage/Posts/types";

import { NormalizedPostForm, PostFormFields } from "./types";

export function normalizeSubmittedFormData(type: ArticleType, id?: number) {
    return function (formData: PostFormFields): NormalizedPostForm<typeof id> {
        return {
            id,
            type,
            ...formData,
        };
    };
}
