import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    root: {
        mt: 6,
        borderRadius: 12,
        overflow: "hidden",
        backgroundColor: "white",
    },
    thead: {
        backgroundColor: "#51749D",

        "& th": {
            py: 4,
            color: "white",
        },
    },
    row: {
        cursor: "pointer",

        _hover: {
            backgroundColor: "#ECF0F2",
        },
    },
    place: {
        ...typography(13, 14, 600),
        color: "gray.500",
    },
    avatar: {
        h: 7,
        w: 7,

        "& > div": {
            ...typography(13, 18, 500),
        },
    },
    name: {
        ...typography(13, 16, 600),
        color: "gray.400",
        ml: 5,
    },
};
