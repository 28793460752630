import { Styles } from "types";

export const styles: Styles = {
    root: {
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: "white",
        width: 236,
        height: "100%",
        p: "25px 24px 31px 24px",
        flexShrink: 0,
        flexDirection: "column",
        boxShadow:
            "0px 24px 70px rgba(0, 0, 0, 0.09), 0px 7.2353px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 3.00517px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.08691px 3.62304px rgba(0, 0, 0, 0.0243888)",
    },
};
