import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    heading: {
        justifyContent: "space-between",
        alignItems: "center",
        mb: 10,
    },
    question: {
        ...typography(16, 19, 700),
    },
    answerContainer: {
        w: 228,
    },
};
