import { Styles } from "types";

export const styles: Styles = {
    container: {
        overflow: "hidden",
        borderRadius: 8,
        w: 387,
        h: 218,
    },
    image: {
        objectFit: "cover",
        h: "100%",
        w: "100%",
    },
};
