import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    body: {
        pt: 12,
        pb: 16,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    iconsContainer: {
        mb: 6,
    },
    heading: {
        ...typography(20, 24, 700),
        mb: 2,
    },
    info: {
        ...typography(14, 17, 600),
        color: "gray.500",
    },
    rocket: {
        w: "45px",
        h: "60px",
    },
    coin: {
        w: "42px",
        h: "44px",
    },
    thunder: {
        w: "26px",
        h: "50px",
    },
};
