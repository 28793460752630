import { Heading, Spinner, Text } from "@chakra-ui/react";
import { FC } from "react";

import { useGetLeaderBoardQuery } from "./api";
import { Board } from "./Board";
import { styles } from "./styles";

export const Leaderboard: FC = () => {
    const { data, isLoading } = useGetLeaderBoardQuery();

    return (
        <>
            <Heading>Leaderboard</Heading>
            {isLoading && <Spinner size="xl" />}
            {data && (
                <>
                    <Text sx={styles.usersCount}>{data.usersCount} user in community</Text>
                    <Board leaders={data.leaders} />
                </>
            )}
        </>
    );
};
