import { Box, Radio, SystemStyleObject, UseRadioProps } from "@chakra-ui/react";
import noop from "lodash/noop";
import { FC } from "react";

import { styles } from "./styles";

const { option, correctOption, wrongOption } = styles;

type Props = UseRadioProps & {
    label: string;
    isTrue: boolean;
    isAnswered: boolean;
};

export const RadioOption: FC<Props> = ({ label, isTrue, isAnswered, ...props }) => {
    const containerStyles: SystemStyleObject = {
        ...option,
        ...(isAnswered ? (isTrue ? correctOption : props.isChecked ? wrongOption : {}) : {}),
    };

    const radioProps = (isAnswered && isTrue) || { ...props, onChange: isAnswered ? noop : props.onChange };

    return (
        <Box as="label" sx={containerStyles}>
            <Radio
                colorScheme={props.isChecked && !isTrue ? "red" : "green"}
                isChecked={props.isChecked || (isAnswered && isTrue)}
                spacing={2}
                {...radioProps}
            >
                {label}
            </Radio>
        </Box>
    );
};
