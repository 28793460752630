import { UseFormProps } from "react-hook-form";

import { RegisterFormType } from "./types";

export const formProps: UseFormProps<RegisterFormType> = {
    defaultValues: {
        name: "",
        surname: "",
        email: "",
        password: "",
        confirmPassword: "",
    },
};

export const requiredErrorMessage = "This field is required";
