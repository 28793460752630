import { CheckCircleIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, ModalBody, ModalFooter, Text } from "@chakra-ui/react";
import { FC } from "react";

import { modalFooter } from "../commonStyles";
import { CommonProps } from "../types";
import { styles } from "./styles";

export const UnlockSent: FC<CommonProps> = ({ onClose }) => {
    return (
        <>
            <ModalBody as={Flex} sx={styles.body}>
                <CheckCircleIcon sx={styles.icon} />
                <Heading sx={styles.heading}>Successfully sent</Heading>
                <Text sx={styles.info}>We will connect with you!</Text>
            </ModalBody>
            <ModalFooter as={Button} colorScheme="green" sx={modalFooter} onClick={onClose}>
                Ok, got it
            </ModalFooter>
        </>
    );
};
