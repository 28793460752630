import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    container: {
        border: "1px dashed rgba(28, 55, 90, 0.16)",
        borderRadius: 8,
        backgroundColor: "blue.50",
        px: 4,
        py: 5,
    },
    uploadButton: {
        ...typography(16, 19, 500),
        boxShadow: "none",
        backgroundColor: "rgba(25, 59, 103, 0.05)",
        color: "#1676F3",
        h: 9,
    },
    dropInfo: {
        ...typography(16, 26, 400),
        color: "rgba(27, 43, 65, 0.72)",
    },
    fileName: {
        ...typography(16, 19, 400),
        whiteSpace: "nowrap",
        maxWidth: "70%",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    removeButton: {
        ml: "auto !important",
        boxShadow: "none",
        background: "transparent",
        minWidth: "unset",
        h: 6,
        w: 6,
    },
    line: {
        width: 170,
        height: "1px",
        backgroundColor: "rgba(27, 43, 65, 0.72)",
    },
    or: {
        ...typography(14, 17, 600),
        color: "rgba(27, 43, 65, 0.72)",
    },
    playButton: {
        ...typography(14, 16, 700),
        backgroundColor: "blue.900",
        alignSelf: "flex-start",

        _hover: {
            backgroundColor: "blue.800",
        },
    },
};
