import { HorizontalScroll } from "components/HorizontalScroll";
import { FC } from "react";

import { Academy } from "./Academy";
import { FreeLesson } from "./FreeLesson";
import { Trial } from "./Trial";

export const Banners: FC = () => {
    return (
        <HorizontalScroll spacing={6}>
            <Academy />
            <FreeLesson />
            <Trial />
        </HorizontalScroll>
    );
};
