import { FormControl, FormLabel, HStack, Radio, Stack } from "@chakra-ui/react";
import { compose } from "@reduxjs/toolkit";
import { QuizType } from "containers/Dashboard/Games/store";
import { FC, useEffect } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { QuestionType } from "types/question";

import { FormInput } from "../../../components/FormInput";
import { Input } from "../../../components/FormInput/Input";
import { FORM_ID } from "../consts";
import { initialAnswerValue } from "./consts";
import { QuestionFormType } from "./types";
import { transformRawFormData } from "./utils";

type Props = {
    quizType: QuizType;
    onSubmit: (data: QuestionType) => void;
    question?: QuestionType;
};

export const Form: FC<Props> = ({ quizType, onSubmit, question }) => {
    const { control, handleSubmit, setValue, getValues } = useFormContext<QuestionFormType>();
    const { fields } = useFieldArray({ name: "answers" as never, control });
    const type = useWatch({ name: "type", control });

    useEffect(() => {
        if (type === "multi") {
            setValue(
                "answers",
                getValues("answers").concat(new Array(4 - getValues("answers").length).fill(initialAnswerValue))
            );
        }
    }, [type]);

    const presentationLabel = quizType === "VIDEO" ? "Video" : "Photo";

    return (
        <Stack as="form" spacing={12} id={FORM_ID} onSubmit={handleSubmit(compose(onSubmit, transformRawFormData))}>
            <Stack spacing={6}>
                <FormInput label="Select type of answer" type="radio" name="type">
                    <HStack spacing={6}>
                        <Radio bg="white" value="multi" colorScheme="green">
                            Multi
                        </Radio>
                        <Radio bg="white" value="text" colorScheme="green">
                            Text
                        </Radio>
                    </HStack>
                </FormInput>
                <FormInput label="Question" type="textarea" name="body" isRequired />
                {type === "text" ? (
                    <FormInput label="Answer (required)" type="input" name="correctAnswer" isRequired />
                ) : (
                    <FormInput type="radio" name="correctAnswer">
                        <Stack spacing={6}>
                            {fields.map((field, index) => (
                                <Controller
                                    render={({ field: { value, onChange } }) => {
                                        value = value === initialAnswerValue ? value.trim() : value;

                                        return (
                                            <FormControl key={field.id}>
                                                <FormLabel pl={10}>
                                                    Answer {index + 1} {index <= 1 ? "(Required)" : ""}
                                                </FormLabel>
                                                <HStack spacing={6}>
                                                    <Radio
                                                        bg="white"
                                                        colorScheme="green"
                                                        value={value || Math.random()}
                                                    />
                                                    <Input onChange={onChange} value={value} />
                                                </HStack>
                                            </FormControl>
                                        );
                                    }}
                                    key={field.id}
                                    name={`answers.${index}`}
                                    control={control}
                                />
                            ))}
                        </Stack>
                    </FormInput>
                )}
                <FormInput label={presentationLabel} type="file" name="file" attachmentOwnerType="quiz" />
            </Stack>
        </Stack>
    );
};
