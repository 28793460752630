import { SystemStyleObject, Text, TextProps } from "@chakra-ui/react";
import { FC } from "react";
import { typography } from "theme/mixins";

const styles: SystemStyleObject = {
    ...typography(14, 14, 700),
    width: "min-content",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
};

export const BaseTextGradient: FC<TextProps> = ({ background, children, sx, ...textProps }) => {
    return (
        <Text sx={{ ...styles, ...sx }} background={background}>
            {children}
        </Text>
    );
};
