import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    body: {
        pt: 8,
        pb: 50,
        alignItems: "center",
        textAlign: "center",
    },
    heading: {
        ...typography(20, 24, 700),
    },
    info: {
        ...typography(14, 17, 600),
        color: "gray.500",
    },
};
