import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    root: {
        justifyContent: "space-between",
        alignItems: "center",
    },
    hint: {
        ...typography(13, 14, 500),
        color: "gray.500",

        "&:not(:last-of-type)": {
            mb: 2,
        },

        "& img": {
            ml: 1,
        },
    },
    thunder: {
        h: "14px",
        w: "7px",
    },
    coin: {
        w: "13px",
        h: "14px",
    },
    skipButton: {
        height: 9,
        width: 110,
        backgroundColor: "white",
    },
};
