import { trial } from "assets/images/banners";
import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    trial: {
        background: `url("${trial}") center/contain no-repeat, #937DFB`,
        pt: 21,
        textAlign: "center",
    },
    title: {
        ...typography(23, 28, 700),
        mb: 19,
        color: "white",
    },
    button: {
        ...typography(13, 16, 600),
        color: "white",
        backgroundColor: "#FFB81E",
        h: 9,
    },
};
