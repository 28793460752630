import { typography } from "theme/mixins";

export const styles = {
    menu: {
        mt: 12,
    },
    menuItem: {
        color: "gray.300",
        alignItems: "center",
        transition: "all .1s linear",
        cursor: "pointer",

        _hover: {
            color: "blue.900",
        },
    },
    arrowIcon: {
        h: 6,
        w: 6,
    },
    menuItemLabel: {
        ...typography(16, 19, 600),
        ml: 2,
    },
    subItem: {
        borderRadius: 4,
        px: 6,
        py: 3,

        _hover: {
            color: "blue.900",
            backgroundColor: "gray.50",
        },
    },
    ellipsis: {
        h: 2,
        w: 2,
        borderRadius: "full",
    },
    subItemLabel: {
        ml: 4,
    },
};
