import { Box, HStack, StackProps } from "@chakra-ui/react";
import * as React from "react";

import { Styles } from "../types";

const styles: Styles = {
    root: {
        overflowX: "auto",
        mx: "-48px",

        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
};
export const HorizontalScroll: React.FC<StackProps> = (props) => (
    <Box sx={styles.root}>
        <HStack {...props} px={12} w="max-content" />
    </Box>
);
