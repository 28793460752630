import { Box, Heading } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";

import { Input } from "../components/FormInput/Input";
import { StudentsTable } from "./Table";

export const Students = () => {
    const [searchKeyword, setSearchKeyword] = useState<string | undefined>(undefined);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);
    };

    return (
        <Box>
            <Heading>Students</Heading>
            <Box maxW={379} mt={6} mb={8}>
                <Input placeholder="Search students" value={searchKeyword} onChange={handleInputChange} />
            </Box>
            <StudentsTable searchKeyword={searchKeyword} />
        </Box>
    );
};
