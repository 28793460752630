import { ROUTES } from "app/routes/constants";
import { GamesICon, HomeIcon, LeaderboardIcon, StoreIcon } from "assets/images/sidebar";

import { MenuItem } from "./types";

export const menuItems: Array<MenuItem> = [
    { label: "Home", Icon: HomeIcon, path: ROUTES.DASHBOARD.HOME },
    {
        label: "Games",
        Icon: GamesICon,
        path: ROUTES.DASHBOARD.GAMES.PATH,
        subItems: [
            { label: "Quiz", path: ROUTES.DASHBOARD.GAMES.CLASSIC_QUIZ, ellipsisColor: "#3CBA48" },
            { label: "Video quiz", path: ROUTES.DASHBOARD.GAMES.VIDEO_QUIZ, ellipsisColor: "#6984C9" },
        ],
    },
    { label: "Leaderboard", Icon: LeaderboardIcon, path: ROUTES.DASHBOARD.LEADERBOARD },
    { label: "Store", Icon: StoreIcon, path: ROUTES.DASHBOARD.STORE },
];

export const adminMenuItems: Array<MenuItem> = [
    { label: "Our social life", path: ROUTES.ADMIN.OUR_SOCIAL_LIFE.PATH },
    { label: "Blog", path: ROUTES.ADMIN.BLOG.PATH },
    { label: "Quiz", path: ROUTES.ADMIN.QUIZ.PATH },
    { label: "Store", path: ROUTES.ADMIN.STORE.PATH },
    { label: "Students", path: ROUTES.ADMIN.STUDENTS.PATH },
];
