import { SimpleGrid } from "@chakra-ui/react";
import { FC } from "react";

import { Post } from "./Post";
import { styles } from "./styles";
import { PostType } from "./types";

export const Posts: FC<Record<"posts", PostType[]>> = ({ posts }) => {
    return (
        <SimpleGrid sx={styles.grid} columnGap={4} rowGap={12}>
            {posts.map((post) => (
                <Post key={post.id} {...post} />
            ))}
        </SimpleGrid>
    );
};
