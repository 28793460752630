import { Input as ChakraInput, InputProps } from "@chakra-ui/react";
import { FC } from "react";

import { styles } from "./styles";

export type Props = InputProps;

export const Input: FC<Props> = (props) => {
    return <ChakraInput id={props.name} placeholder="Type here" sx={styles.input} {...props} />;
};
