import { UseFormProps } from "react-hook-form";

import { LoginFormType } from "./types";

export const formProps: UseFormProps<LoginFormType> = {
    defaultValues: {
        email: "",
        password: "",
    },
};
