import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading } from "@chakra-ui/react";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetPostsQuery } from "./api";
import { useArticleType } from "./hooks";
import { Posts } from "./Posts";
import { styles } from "./styles";

export const ArticlesPage: FC = () => {
    const articleType = useArticleType();
    const { data } = useGetPostsQuery(articleType);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const addPost = () => {
        navigate(`${pathname}/create`);
    };

    const header = articleType === "BLOG" ? "Blog" : "Our social life";

    return (
        <>
            <Flex sx={styles.header}>
                <Heading>{header}</Heading>
                <Button onClick={addPost} colorScheme="green" leftIcon={<AddIcon />}>
                    Add article
                </Button>
            </Flex>
            {data && <Posts posts={data} />}
        </>
    );
};
