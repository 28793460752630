import { Button, Flex, Heading, ModalBody, ModalFooter, Text } from "@chakra-ui/react";
import { ReactComponent as MedalIcon } from "assets/images/notification/medal.svg";
import { Level } from "components/TextGradient";
import { FC } from "react";

import { modalFooter } from "../commonStyles";
import { CommonProps } from "../types";
import { styles } from "./styles";

type Props = CommonProps & {
    level: number;
};

export const LevelReach: FC<Props> = ({ level, onClose }) => {
    return (
        <>
            <ModalBody as={Flex} sx={styles.body}>
                <MedalIcon />
                <Heading sx={styles.heading}>Congratulation</Heading>
                <Text sx={styles.info}>You’ve reached level</Text>
                <Level value={level} sx={styles.level} />
            </ModalBody>
            <ModalFooter as={Button} colorScheme="green" sx={modalFooter} onClick={onClose}>
                Got it!
            </ModalFooter>
        </>
    );
};
