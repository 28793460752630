import { FC } from "react";

type Props = {
    total: number;
    current: number;
};

export const ProgressBar: FC<Props> = ({ total, current, children }) => {
    const size = 38;
    const center = size / 2;
    const strokeWidth = 3;
    const radius = center - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (current / total) * circumference;

    return (
        <svg width={size} height={size} style={{ verticalAlign: "middle" }}>
            <circle fill="none" stroke="#DEDEDE" strokeWidth={strokeWidth} cx={center} cy={center} r={radius} />
            <circle
                style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }}
                fill="none"
                stroke="#F2C31C"
                strokeLinecap="round"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeWidth={strokeWidth}
                cx={center}
                cy={center}
                r={radius}
            />
            <text
                style={{ fontSize: "15px", lineHeight: "18px", fontWeight: 700 }}
                textAnchor="middle"
                alignmentBaseline="middle"
                x={center}
                y={center + 1}
            >
                {total}
            </text>
        </svg>
    );
};
