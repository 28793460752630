import { ChakraProvider } from "@chakra-ui/react";
import { AppRoutes } from "app/routes";
import { Router } from "app/routes/Router";
import { persistedStore, store } from "app/store";
import { createBrowserHistory } from "history";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { theme } from "theme";
import { Fonts } from "theme/Fonts";

import { Notification } from "../containers/Notification/ui";

export const history = createBrowserHistory();

export const App = () => (
    <Router history={history}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore}>
                <ChakraProvider theme={theme}>
                    <Fonts />
                    <Notification />
                    <AppRoutes />
                </ChakraProvider>
            </PersistGate>
        </Provider>
    </Router>
);
