import { Box, Button, Heading, HStack, Spinner } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { useAppDispatch } from "app/store/hooks";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { QuestionType } from "types/question";

import { requestDeletion } from "../../store/deletionSlice";
import { useGetPackQuery } from "../Pack/api";
import {
    useCreateQuestionMutation,
    useDeleteQuestionMutation,
    useGetQuestionQuery,
    useUpdateQuestionMutation,
} from "./api";
import { FORM_ID } from "./consts";
import { Form } from "./Form";
import { getDefaultValues } from "./Form/consts";
import { QuestionFormType } from "./Form/types";
import { styles } from "./styles";
import { prepareMutateQuestionRequestBody } from "./utils";

export type QuizParams = Record<"questionId" | "quizId", string>;

export const QuestionEditor = () => {
    let { questionId = "", quizId = "" } = useParams<QuizParams>();
    const navigate = useNavigate();
    const [deleteQuestion] = useDeleteQuestionMutation();
    const quizQuery = useGetPackQuery(quizId);
    const questionQuery = useGetQuestionQuery(questionId, { skip: !questionId });
    const [createQuestion] = useCreateQuestionMutation();
    const [updateQuestion] = useUpdateQuestionMutation();
    const form = useForm<QuestionFormType>({ defaultValues: getDefaultValues(questionQuery.data), mode: "onChange" });
    const dispatch = useAppDispatch();

    useEffect(() => {
        form.reset(getDefaultValues(questionQuery.data));
    }, [questionQuery.data]);

    const handleClickDelete = () => {
        dispatch(
            requestDeletion({
                onConfirm: () => deleteQuestion({ questionId: Number(questionId), quizId: Number(quizId) }),
                entityType: "question",
            })
        );
    };

    const goToQuiz = () => {
        navigate(`${ROUTES.ADMIN.QUIZ.PACK}/${quizId}`);
    };

    const handleSubmit = (data: QuestionType) => {
        if (quizId) {
            const mutation = questionId ? updateQuestion : createQuestion;
            mutation(prepareMutateQuestionRequestBody({ id: Number(questionId), ...data }, Number(quizId))).then(
                goToQuiz
            );
        }
    };

    if (!questionId && !quizId) return null;

    return (
        <>
            <HStack sx={styles.header}>
                <Heading>Quiz</Heading>
                <HStack spacing={4}>
                    <Button type="submit" form={FORM_ID} colorScheme="green" sx={styles.button}>
                        Save
                    </Button>
                    {questionId && (
                        <Button onClick={handleClickDelete} colorScheme="red" sx={styles.button}>
                            Delete
                        </Button>
                    )}
                </HStack>
            </HStack>
            <Box sx={styles.formContainer}>
                <FormProvider {...form}>
                    {questionId ? ( // Edit form
                        <>
                            {questionQuery.isLoading && <Spinner size="xl" />}
                            {questionQuery.data && quizQuery.data && (
                                <Form
                                    quizType={quizQuery.data.type}
                                    onSubmit={handleSubmit}
                                    question={questionQuery.data}
                                />
                            )}
                        </>
                    ) : (
                        // Create form
                        <>
                            {quizQuery.isLoading && <Spinner size="xl" />}
                            {quizQuery.data && <Form quizType={quizQuery.data.type} onSubmit={handleSubmit} />}
                        </>
                    )}
                </FormProvider>
            </Box>
        </>
    );
};
