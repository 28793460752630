import { HStack, Image } from "@chakra-ui/react";
import { useAppSelector } from "app/store/hooks";
import { coin, rocket, thunder } from "assets/images/header/achievements";
import { Coins, Level, Points } from "components/TextGradient";
import { selectUser } from "containers/Auth/slice/selectors";
import { FC } from "react";

import { Item } from "./Item";

export const Achievements: FC = () => {
    const user = useAppSelector(selectUser);

    if (!user) {
        return null;
    }

    return (
        <HStack spacing={4} ml={10}>
            {[
                {
                    label: "Level",
                    result: <Level value={user.level} />,
                    icon: <Image src={rocket} ml="9px" h="44px" />,
                },
                {
                    label: "Points",
                    result: <Points value={user.points} />,
                    icon: <Image src={thunder} h={37} ml={2} mt="5px" mr="7px" />,
                },
                {
                    label: "Coins",
                    result: <Coins value={user.coins} />,
                    icon: <Image src={coin} h={8} ml={1} mt="7px" mr="5px" />,
                },
            ].map((item) => (
                <Item key={item.label} {...item} />
            ))}
        </HStack>
    );
};
