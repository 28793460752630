import { Styles } from "types";

export const styles: Styles = {
    root: {
        borderBottom: "1px solid",
        borderColor: "gray.200",
        py: 5,
        pl: 12,
        pr: 8,
        alignItems: "center",
    },
    logoutButton: {
        ml: "auto",
        boxShadow: "none",
    },
};
