import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    card: {
        px: 2,
        pt: 2,
        pb: 4,
        backgroundColor: "white",
        borderRadius: 12,
        width: 300,
        cursor: "pointer",
        boxShadow:
            "0px 24px 70px rgba(0, 0, 0, 0.09), 0px 7.2353px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 3.00517px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.08691px 3.62304px rgba(0, 0, 0, 0.0243888)",
    },
    header: {
        ...typography(30, 33, 700),
        height: 130,
        borderRadius: 6,
        pt: 30,
        pl: 4,
        mb: 3,
        position: "relative",

        "& > img": {
            position: "absolute",
            bottom: 0,
        },
    },
    info: {
        px: 2,
    },
    questionInfo: {
        ...typography(13, 16, 600),
        color: "gray.500",
    },
    description: {
        ...typography(13, 18, 500),
        color: "gray.500",
        mt: 2,
    },
};
