import { Styles } from "types";

export const styles: Styles = {
    header: {
        justifyContent: "space-between",
        mb: 5,
    },
    formContainer: {
        maxW: 379,
    },
    button: {
        w: 120,
    },
};
