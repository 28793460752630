import { FormControl, FormErrorMessage, FormLabel, Input, InputProps } from "@chakra-ui/react";
import { FC } from "react";
import { useController, UseControllerProps, useFormContext } from "react-hook-form";

import { styles } from "./styles";

interface Props extends InputProps {
    name: string;
    label: string;
    rules?: UseControllerProps["rules"];
}

export const FormInput: FC<Props> = ({ name, label, rules, ...inputProps }) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control, rules });

    return (
        <FormControl isInvalid={Boolean(fieldState.error?.message)}>
            <FormLabel htmlFor={name} sx={styles.label}>
                {label}
            </FormLabel>
            <Input id={name} {...field} {...inputProps} />
            <FormErrorMessage sx={styles.error}>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
    );
};
