import { Styles } from "types";

import { typography } from "../../../theme/mixins";

export const styles: Styles = {
    heading: {
        ...typography(18, 22, 600),
    },
    content: {
        ...typography(15, 18, 500),
        color: "gray.500",
    },
    body: {
        pt: 6,
        pb: 89,
    },
};
