import { Flex } from "@chakra-ui/react";
import { useAppSelector } from "app/store/hooks";
import { selectIsAdmin } from "containers/Auth/slice/selectors";
import { FC } from "react";

import { Achievements } from "./Achievements";
import { LogoutButton } from "./LogoutButton";
import { styles } from "./styles";
import { UserGreetings } from "./UserGreetings";

export const Header: FC = () => {
    const isAdmin = useAppSelector(selectIsAdmin);

    return (
        <Flex sx={styles.root}>
            <UserGreetings />
            {!isAdmin && <Achievements />}
            <LogoutButton sx={styles.logoutButton} />
        </Flex>
    );
};
