import { Box, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";

import { styles } from "./styles";
import { AchievementItem } from "./types";

export const Item: FC<AchievementItem> = ({ icon, result, label }) => {
    return (
        <Flex sx={styles.item}>
            {icon}
            <Box sx={styles.info}>
                {result}
                <Text sx={styles.label}>{label}</Text>
            </Box>
        </Flex>
    );
};
