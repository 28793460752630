import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import { styles } from "./styles";

export const RightSide = () => (
    <Flex sx={styles.root}>
        <Outlet />
    </Flex>
);
