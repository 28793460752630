import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    input: {
        backgroundColor: "#F4F6FD",

        "&::placeholder": {
            ...typography(14, 17, 500),
            color: "#D0D6EB",
            fontStyle: "italic",
        },
    },
    correctAnswer: {
        backgroundColor: "rgba(58, 194, 128, 0.1)",
        borderColor: "#3AC280",
    },
    wrongAnswer: {
        backgroundColor: "rgba(229, 122, 122, 0.1)",
        borderColor: "red.500",
    },
    hint: {
        ...typography(11, 13, 500),
        color: "gray.500",
        mt: 1,
    },
    correct: {
        ...typography(16, 19, 600),
    },
};
