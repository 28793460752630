import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "app/store";
import { User } from "types/user";

import { AuthBranch } from "./types";

export const getAuthBranch = (state: ApplicationState) => state.auth;
export const isUserAdmin = (user: User | null) => user && user.role === "ADMIN";
export const getUser = ({ user }: AuthBranch): User | null => user;

export const selectUser = createSelector(getAuthBranch, getUser);
export const selectIsAdmin = createSelector(selectUser, isUserAdmin);
export const selectToken = createSelector(getAuthBranch, ({ token }) => token);
export const selectRefreshToken = createSelector(getAuthBranch, ({ refreshToken }) => refreshToken);
