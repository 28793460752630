import { api } from "app/api";
import { User } from "types/user";

import { SwitchRoleRequestOptions } from "./types";

const injectedStudentsEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getStudents: builder.query<User[], string | undefined>({
            query: (searchKeyword) => ({
                url: `users`,
                params: { email: searchKeyword },
            }),
            providesTags: (result) =>
                result ? [...result.map(({ id }) => ({ type: "Student" as const, id })), "Student"] : [],
        }),
        changeRole: builder.mutation<void, SwitchRoleRequestOptions>({
            query: ({ id, role }) => {
                console.log(id, role);
                return {
                    url: `users/${id}`,
                    method: "POST",
                    body: { role },
                };
            },
            invalidatesTags: (result, error, { id }) => (result === undefined ? [] : [{ type: "Student", id }]),
        }),
    }),
});

export const { useGetStudentsQuery, useChangeRoleMutation } = injectedStudentsEndpoints;
