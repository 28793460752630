/* eslint-disable indent */
import { api } from "app/api";

import { PackTypeForAdmin } from "../types";

const injectedPackEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getPack: builder.query<PackTypeForAdmin, string>({
            query: (id) => `quiz/admin/${id}`,
            providesTags: (pack) => (pack ? [{ type: "Pack", id: pack.id }] : ["Pack"]),
        }),
        activate: builder.mutation<void, number>({
            query: (id) => ({ url: `quiz/admin/status/${id}`, method: "PATCH" }),
            invalidatesTags: (_result, error, id) =>
                error
                    ? []
                    : [
                          { type: "QuizListItem", id },
                          { type: "Pack", id },
                      ],
        }),
    }),
});

export const { useGetPackQuery, useActivateMutation } = injectedPackEndpoints;
