import { Image } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { classic, soon, video } from "assets/images/gamesCards";

import { GamesCardType } from "./Card/types";

export const cards: Array<GamesCardType> = [
    {
        header: {
            color: "#41B080",
            label: (
                <>
                    Classic
                    <br />
                    Quiz
                </>
            ),
            background: "linear-gradient(180deg, #6DDEAD 0%, #33CB97 100%)",
        },
        illustration: <Image src={classic} w={132} h={172} right={0} />,
        questionDuration: 30,
        questionsCount: 75,
        url: ROUTES.DASHBOARD.GAMES.CLASSIC_QUIZ,
        description:
            "Answer questions, learn the language and accumulate experience. A test in the form of classic quiz",
    },
    {
        header: {
            label: (
                <>
                    Video
                    <br />
                    Quiz
                </>
            ),
            background: "linear-gradient(180deg, #6DC3DE 0%, #338BCB 100%)",
            color: "#2C85C6",
        },
        questionsCount: 75,
        questionDuration: 30,
        url: ROUTES.DASHBOARD.GAMES.VIDEO_QUIZ,
        illustration: <Image src={video} w={154} h={169} right="9px" />,
        description:
            "Answer questions, learn the language and accumulate experience. A test in the form of classic quiz",
    },
    {
        header: {
            label: (
                <>
                    Coming
                    <br />
                    Soon
                </>
            ),
            color: "#898989",
            background: "linear-gradient(180deg, #DADADA 0%, #8C8D8D 100%)",
        },
        description: "New quiz will be added soon",
        questionsCount: 75,
        questionDuration: 30,
        illustration: <Image src={soon} w={129} h={171} right={2} />,
    },
    {
        header: {
            label: (
                <>
                    Coming
                    <br />
                    Soon
                </>
            ),
            color: "#898989",
            background: "linear-gradient(180deg, #DADADA 0%, #8C8D8D 100%)",
        },
        description: "New quiz will be added soon",
        questionsCount: 75,
        questionDuration: 30,
        illustration: <Image src={soon} w={129} h={171} right={2} />,
    },
];
