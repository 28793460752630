import { Styles } from "types";

export const styles: Styles = {
    root: {
        height: "100%",
        px: 6,
        py: 8,
        flexDirection: "column",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
    },
};
