import { typography } from "theme/mixins";
import { Styles } from "types";

export const commonStyles: Styles = {
    logo: {
        mb: "43px",
    },
    welcome: {
        color: "gray.500",
        fontWeight: 400,
    },
    formContainer: {
        minWidth: 331,
        justifyContent: "flex-end",
        flexDirection: "column",
        flex: 1,
    },
    form: {
        mt: 8,
    },
    submitButton: {
        width: "100%",
    },
    bottomLink: {
        ...typography(16, 19, 500),
        color: "gray.600",

        "& > a": {
            color: "blue.600",
        },
    },
};
