import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    container: {
        w: 188,
        h: 122,
        background: "linear-gradient(180deg, rgba(254, 183, 56, 0) 0%, rgba(254, 183, 56, 0.25) 100%)",
        borderRadius: 12,
        px: "19px",
        py: 4,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
    },
    info: {
        ...typography(12, 15, 600),
        color: "gray.500",
    },
    button: {
        w: "100%",
        h: 9,
    },
};
