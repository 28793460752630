import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    container: {
        maxWidth: 820,
    },
    frame: {
        backgroundColor: "white",
        height: 443,
        px: 8,
        py: 6,
        borderRadius: 12,
        border: "1px solid #E4E4E4",
        mb: 4,
    },
    header: {
        justifyContent: "space-between",
        mb: 6,
    },
    successMessage: {
        ...typography(14, 21, 700),
        color: "gray.300",
        justifyContent: "space-between",
        textAlign: "center",
        mr: "auto",
        ml: 155,
    },
    correctAnswers: {
        ...typography(14, 17, 700),
        color: "gray.300",
        mt: 1,
    },
};
