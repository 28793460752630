import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { ApplicationState } from "app/store";

import { injectedBlogEndpoints } from "../ArticlesPage/api";
import { injectedQuizListEndpoints } from "../Quiz/api";
import { injectedQuestionEndpoints } from "../Quiz/QuestionEditor/api";
import { injectedStoreEndpoints } from "../StorePage/api";

const { deleteQuestion } = injectedQuestionEndpoints.endpoints;
const { deletePost } = injectedBlogEndpoints.endpoints;
const { deletePack } = injectedQuizListEndpoints.endpoints;
const { deleteGood } = injectedStoreEndpoints.endpoints;

export type DeletionBranch = {
    onConfirm?: () => void;
    entityType?: string;
    status: QueryStatus;
};

export type DeletionRequestPayload = Required<Pick<DeletionBranch, "onConfirm" | "entityType">>;

const initialState: DeletionBranch = {
    status: QueryStatus.uninitialized,
};

const deletionSlice = createSlice({
    name: "deletion",
    initialState,
    reducers: {
        requestDeletion: (state, { payload }: PayloadAction<DeletionRequestPayload>) => {
            state.entityType = payload.entityType;
            state.onConfirm = payload.onConfirm;
        },
        resetDeletion: (state) => {
            state.entityType = undefined;
            state.onConfirm = undefined;
            state.status = QueryStatus.uninitialized;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(
                    deletePost.matchPending,
                    deleteQuestion.matchPending,
                    deletePack.matchPending,
                    deleteGood.matchPending
                ),
                (state) => {
                    state.status = QueryStatus.pending;
                }
            )
            .addMatcher(
                isAnyOf(
                    deletePost.matchFulfilled,
                    deleteQuestion.matchFulfilled,
                    deletePack.matchFulfilled,
                    deleteGood.matchFulfilled
                ),
                (state) => {
                    state.entityType = undefined;
                    state.onConfirm = undefined;
                    state.status = QueryStatus.fulfilled;
                }
            );
    },
});

export const getDeletionBranch = (state: ApplicationState) => state.deletion;

export const {
    reducer: deletionReducer,
    actions: { requestDeletion, resetDeletion },
} = deletionSlice;
