import { Flex, HStack, Image } from "@chakra-ui/react";
import { coin, thunder } from "assets/images/header/achievements";
import { Coins, Points } from "components/TextGradient";
import { FC } from "react";

import { styles } from "./styles";

export const Achievements: FC<Record<"coins" | "points", number>> = ({ coins, points }) => {
    return (
        <HStack sx={styles.root} spacing={6}>
            <Flex sx={styles.item}>
                <Coins value={coins} sx={styles.value} />
                <Image src={coin} sx={{ ...styles.coinIcon, ...styles.icon }} />
            </Flex>
            <Flex sx={styles.item}>
                <Points value={points} sx={styles.value} />
                <Image src={thunder} sx={{ ...styles.thunderIcon, ...styles.icon }} />
            </Flex>
        </HStack>
    );
};
