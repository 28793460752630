import { Button, Text } from "@chakra-ui/react";
import { FC } from "react";

import { Banner } from "../Banner";
import { styles } from "./styles";

export const Trial: FC = () => {
    return (
        <Banner sx={styles.trial}>
            <Text sx={styles.title}>
                IELTS TRIAL
                <br />
                First in Azerbaijan
            </Text>
            <Button sx={styles.button} colorScheme="yellow" size="md">
                More information
            </Button>
        </Banner>
    );
};
