import { Button, Heading, HStack, Spinner } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { useNavigate, useParams } from "react-router-dom";

import { useCreateGoodMutation, useGetGoodByIDQuery, useUpdateGoodMutation } from "../api";
import { GoodType } from "../types";
import { FORM_ID } from "./consts";
import { Form } from "./Form";
import { normalizeFormData } from "./utils";

export const ProductEditor = () => {
    const { productId } = useParams<{ productId: string }>();
    const { data, isLoading } = useGetGoodByIDQuery(Number(productId), { skip: !productId });
    const [createGood] = useCreateGoodMutation();
    const [updateGood] = useUpdateGoodMutation();
    const navigate = useNavigate();

    const goToStore = () => {
        navigate(ROUTES.ADMIN.STORE.PATH);
    };

    const handleSubmit = (data: GoodType) => {
        const mutation = productId ? updateGood : createGood;

        mutation(normalizeFormData(data)).then(goToStore);
    };

    return (
        <>
            <HStack justify="space-between" mb={4}>
                <Heading>Product</Heading>
                <Button colorScheme="green" w={120} type="submit" form={FORM_ID}>
                    Save
                </Button>
            </HStack>
            {productId ? (
                <>
                    {isLoading && <Spinner size="xl" />}
                    {data && <Form good={data} onSubmit={handleSubmit} />}
                </>
            ) : (
                <Form onSubmit={handleSubmit} />
            )}
        </>
    );
};
