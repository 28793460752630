export const ROUTES = {
    AUTH: {
        PATH: "/auth",
        LOGIN: "/auth/login",
        SIGN_UP: "/auth/sign-up",
    },
    ADMIN: {
        PATH: "/admin",
        OUR_SOCIAL_LIFE: {
            PATH: "/admin/our-social-life",
            EDIT: "/admin/our-social-life/edit",
            CREATE: "/admin/our-social-life/create",
        },
        BLOG: {
            PATH: "/admin/blog",
            EDIT: "/admin/blog/edit",
            CREATE: "/admin/blog/create",
        },
        QUIZ: {
            PATH: "/admin/quiz",
            LIST: "/admin/quiz/list",
            PACK: "/admin/quiz/pack",
            EDIT: "/admin/quiz/:quizId/question/edit",
            CREATE: "/admin/quiz/:quizId/question/create",
        },
        STORE: {
            PATH: "/admin/store",
            EDIT: "/admin/store/edit",
            CREATE: "/admin/store/create",
        },
        STUDENTS: {
            PATH: "/admin/students",
        },
    },
    DASHBOARD: {
        PATH: "/dashboard",
        HOME: "/dashboard/home",
        GAMES: {
            PATH: "/dashboard/games",
            CLASSIC_QUIZ: "/dashboard/games/classic-quiz",
            VIDEO_QUIZ: "/dashboard/games/video-quiz",
        },
        LEADERBOARD: "/dashboard/leaderboard",
        STORE: "/dashboard/store",
    },
};
