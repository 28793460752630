import { QuestionType } from "types/question";

import { QuestionCreateRequest } from "./types";

export const prepareMutateQuestionRequestBody = (question: QuestionType, quizId: number): QuestionCreateRequest => {
    return {
        quizId,
        question: {
            ...question,
            answers: question.answers.filter((answer) => answer.body.trim()),
        },
    };
};
