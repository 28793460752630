import { Box, Button, Heading, HStack } from "@chakra-ui/react";
import { useAppDispatch } from "app/store/hooks";
import { FC } from "react";
import { useParams } from "react-router-dom";

import { requestDeletion } from "../../store/deletionSlice";
import { useDeletePostMutation, useGetPostQuery } from "../api";
import { FORM_ID } from "./consts";
import { Form } from "./Form";
import { styles } from "./styles";

export const ArticleEditor: FC = () => {
    const { id } = useParams<Record<"id", string>>();

    const [deletePost] = useDeletePostMutation();
    const { data } = useGetPostQuery(id!, { skip: !id });
    const dispatch = useAppDispatch();

    const handleClickDelete = () => {
        if (!data) return;
        dispatch(requestDeletion({ onConfirm: () => deletePost(data), entityType: "post" }));
    };

    return (
        <>
            <HStack sx={styles.header}>
                <Heading>Blog</Heading>
                <HStack spacing={4}>
                    <Button type="submit" form={FORM_ID} colorScheme="green" sx={styles.button}>
                        Save
                    </Button>
                    {id && (
                        <Button onClick={handleClickDelete} colorScheme="red" sx={styles.button}>
                            Delete
                        </Button>
                    )}
                </HStack>
            </HStack>
            <Box sx={styles.formContainer}>{(data || !id) && <Form post={data} />}</Box>
        </>
    );
};
