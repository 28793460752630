import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    imageContainer: {
        h: 158,
        borderRadius: 12,
        overflow: "hidden",
        mb: 4,

        "& > img": {
            objectFit: "cover",
            height: "100%",
            width: "100%",
        },
    },
    date: {
        ...typography(12, 16, 500),
        color: "gray.500",
        mb: 2,
    },
    title: {
        ...typography(16, 19, 700),
        mb: 2,
    },
    description: {
        ...typography(13, 22, 500),
        color: "gray.500",
        mb: 2,
    },
};
