import { Button, Text } from "@chakra-ui/react";
import { FC } from "react";

import { Banner } from "../Banner";
import { styles } from "./styles";

export const Academy: FC = () => {
    return (
        <Banner sx={styles.academy}>
            <Text sx={styles.title}>
                Take the <br /> next step in <br /> your knowledge
            </Text>
            <Button colorScheme="blue" size="md" sx={styles.button}>
                Join to Academy
            </Button>
        </Banner>
    );
};
