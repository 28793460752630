import { Avatar, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useAppSelector } from "app/store/hooks";
import { Level, Points } from "components/TextGradient";
import { selectUser } from "containers/Auth/slice/selectors";
import { FC } from "react";

import { Leader } from "../types";
import { styles } from "./styles";

type Props = {
    leaders: Array<Leader>;
};

export const Board: FC<Props> = ({ leaders }) => {
    return (
        <Table size="md" colorScheme="blackAlpha" sx={styles.root}>
            <Thead sx={styles.thead}>
                <Tr>
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Level</Th>
                    <Th>Points</Th>
                    <Th>Achievements</Th>
                </Tr>
            </Thead>
            <Tbody>
                {leaders.map(({ user: { name, level, points, id }, isCurrent, rowNumber }) => (
                    <Tr sx={styles.row} key={id}>
                        <Td sx={styles.place}>{rowNumber}</Td>
                        <Td>
                            <Flex align="center">
                                <Avatar name={name} sx={styles.avatar} />
                                <Text sx={styles.name}>
                                    {name}
                                    {isCurrent && " (You)"}
                                </Text>
                            </Flex>
                        </Td>
                        <Td>
                            <Level value={level} />
                        </Td>
                        <Td>
                            <Points value={points} />
                        </Td>
                        <Td />
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
