import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { FC } from "react";

import { hideNotification } from "../store";
import { selectNotificationOptions, selectNotificationTemplate } from "../store/selectors";
import { notificationTemplates } from "./templates";

export const Notification: FC = () => {
    const template = useAppSelector(selectNotificationTemplate);
    const options = useAppSelector(selectNotificationOptions);
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(hideNotification());
        options?.onClose();
    };

    if (!template) return null;

    const TemplateComponent = notificationTemplates[template];

    return (
        <Modal isOpen isCentered onClose={close} size="sm">
            <ModalOverlay />
            <ModalContent maxW={337}>
                <TemplateComponent {...options} onClose={close} />
            </ModalContent>
        </Modal>
    );
};
