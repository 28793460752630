import { Flex } from "@chakra-ui/react";
import { useAppSelector } from "app/store/hooks";
import { Logo } from "components/Logo";
import { selectIsAdmin } from "containers/Auth/slice/selectors";
import { FC } from "react";

import { Menu } from "./Menu";
import { styles } from "./styles";
import { UnlockBlock } from "./UnlockBlock";

export const Sidebar: FC = () => {
    const isAdmin = useAppSelector(selectIsAdmin);

    return (
        <Flex sx={styles.root}>
            <Logo />
            <Menu />
            {!isAdmin && <UnlockBlock mt="auto" />}
        </Flex>
    );
};
