import { api } from "app/api";
import { post } from "utils/api";

import { CreateQuizRequestBody } from "./types";

export const injectedQuizEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        createQuiz: builder.mutation<void, CreateQuizRequestBody>({
            query: post("quiz/admin/"),
            invalidatesTags: (_response, _error, args) => ["QuizListItem", args.type],
        }),
    }),
});

export const { useCreateQuizMutation } = injectedQuizEndpoints;
