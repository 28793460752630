import { Button, Stack } from "@chakra-ui/react";
import { compose } from "@reduxjs/toolkit";
import { FC } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { FormInput } from "../../../components/FormInput";
import { useCreatePostMutation, useUpdatePostMutation } from "../../api";
import { useArticleType } from "../../hooks";
import { PostType } from "../../Posts/types";
import { FORM_ID } from "../consts";
import { Block } from "./Block";
import { emptyParagraph, initialPost } from "./consts";
import { styles } from "./styles";
import { PostFormFields } from "./types";
import { normalizeSubmittedFormData } from "./utils";

export const Form: FC<Partial<Record<"post", PostType>>> = ({ post = initialPost }) => {
    const [createPost] = useCreatePostMutation();
    const [updatePost] = useUpdatePostMutation();
    const form = useForm<PostFormFields>({ defaultValues: post });
    const { fields, append, remove } = useFieldArray({ control: form.control, name: "paragraphs" });
    const articleType = useArticleType();

    const request = post.id ? updatePost : createPost;
    const onSubmit = form.handleSubmit(compose(request, normalizeSubmittedFormData(articleType, post.id)));

    const addNewBlock = () => append(emptyParagraph);

    return (
        <>
            <FormProvider {...form}>
                <form id={FORM_ID} onSubmit={onSubmit}>
                    <Stack spacing={6}>
                        <FormInput label="Background photo" type="file" name="image" />
                        <FormInput label="Title" type="input" name="title" />
                        <Stack spacing={12}>
                            {fields.map((field, index) => (
                                <Block key={field.id} index={index} remove={remove} />
                            ))}
                        </Stack>
                    </Stack>
                </form>
            </FormProvider>
            <Button
                onClick={addNewBlock}
                colorScheme="gray"
                variant="outline"
                isLoading={form.formState.isSubmitting}
                sx={styles.addNewBlockButton}
            >
                Add new block
            </Button>
        </>
    );
};
