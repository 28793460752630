import { api } from "app/api";
import { PackTypeForUser } from "containers/Admin/Quiz/types";
import { QuestionForUser } from "types/question";

import { QuizType } from "./store";
import { CheckAnswerRequest, CheckAnswerResponse } from "./types";

export const injectedQuizEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserQuizzes: builder.query<PackTypeForUser[], QuizType>({
            query: (type) => ({
                url: `quiz/`,
                params: { type },
            }),
            providesTags: ["UserQuizListItem"],
        }),
        getUserQuizById: builder.query<PackTypeForUser, string | undefined>({
            query: (id) => `quiz/${id}`,
            providesTags: (_result, _error, id) => [{ type: "UserQuizDetails", id }],
        }),
        startQuiz: builder.mutation<QuestionForUser, number>({
            query: (id) => ({ url: `quiz/${id}`, method: "PATCH" }),
            invalidatesTags: (_result, _error, id) => [{ type: "UserQuizDetails", id }],
        }),
        getNextQuestion: builder.query<QuestionForUser, number | undefined>({
            query: (id) => `quiz/question/next/${id}`,
            providesTags: (_result, _error, id) => [{ type: "QuestionForUser", id }],
        }),
        checkAnswer: builder.mutation<CheckAnswerResponse, CheckAnswerRequest>({
            query: (body) => ({
                url: `quiz/answer/check`,
                method: "POST",
                body,
            }),
            // @ts-ignore
            invalidatesTags: (result, _error, arg) => {
                const tags = [];

                if (result) {
                    if (result.isCorrect) {
                        tags.push({ type: "QuestionForUser", id: arg.QuestionId }, "UserQuizDetails");
                    }

                    if (result.isFinished) {
                        tags.push("User");
                    }
                }

                return tags;
            },
        }),
    }),
});

export const {
    useGetUserQuizzesQuery,
    useGetUserQuizByIdQuery,
    useStartQuizMutation,
    useGetNextQuestionQuery,
    useCheckAnswerMutation,
} = injectedQuizEndpoints;
