import { compose } from "@reduxjs/toolkit";
import { history } from "app";
import { api } from "app/api";
import { post, put } from "utils/api";

import { CreatePostRequest, UpdatePostRequest } from "./Editor/Form/types";
import { ArticleType, PostType } from "./Posts/types";
import { getListPath } from "./utils";

export const injectedBlogEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getPosts: builder.query<PostType[], ArticleType>({
            query: (type) => `public/blogs/articles/${type}`,
            providesTags: (_result, _error, articleType) => [articleType],
        }),
        getPost: builder.query<PostType, string>({
            query: (id) => `public/blogs/article/${id}`,
            providesTags: (post, _error, id) => (post ? [{ type: post.type, id: post.id }, post.type] : ["BLOG"]),
        }),
        createPost: builder.mutation<void, CreatePostRequest>({
            query: post("blogs/article"),
            invalidatesTags: (_result, _error, post) => [post.type],
            async onQueryStarted({ type }, { queryFulfilled }) {
                queryFulfilled.then(() => compose(history.push, getListPath)(type));
            },
        }),
        updatePost: builder.mutation<void, UpdatePostRequest>({
            query: put("blogs/article"),
            async onQueryStarted({ type }, { queryFulfilled }) {
                queryFulfilled.then(() => compose(history.push, getListPath)(type));
            },
            invalidatesTags: (_results, _error, { type }) => [type],
        }),
        deletePost: builder.mutation<void, PostType>({
            query: ({ id }) => ({
                url: `blogs/article/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, { type }) => [type],
        }),
    }),
});

export const {
    useGetPostsQuery,
    useGetPostQuery,
    useCreatePostMutation,
    useDeletePostMutation,
    useUpdatePostMutation,
} = injectedBlogEndpoints;
