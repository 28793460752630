import { Styles } from "types";

export const styles: Styles = {
    root: {
        height: "100vh",
    },
    leftSideContainer: {
        maxWidth: 804,
        height: "100%",
    },
    rightSideContainer: {
        height: "100%",
        flex: 1,
        minWidth: 382,
    },
};
