import { Textarea as ChakraTextarea, TextareaProps } from "@chakra-ui/react";
import { FC } from "react";

import { styles } from "./styles";

export type Props = TextareaProps;

export const Textarea: FC<Props> = (props) => (
    <ChakraTextarea id={props.name} placeholder="Type here" sx={styles.textarea} rows={5} {...props} />
);
