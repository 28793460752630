import { Box, Button, Heading, HStack, Image, Text } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { useAppDispatch } from "app/store/hooks";
import { useDeletePostMutation } from "containers/Admin/ArticlesPage/api";
import { requestDeletion } from "containers/Admin/store/deletionSlice";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { PostType } from "../types";
import { styles } from "./styles";
import { getDate, getShortDescription } from "./utils";

export const Post: FC<PostType> = (post) => {
    const { id, image, title, createdAt, paragraphs, type } = post;
    const [deletePost] = useDeletePostMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleClickDelete = () => {
        dispatch(requestDeletion({ onConfirm: () => deletePost(post), entityType: "post" }));
    };

    const edit = () => {
        const page = type === "NEWS" ? "OUR_SOCIAL_LIFE" : "BLOG";
        navigate(`${ROUTES.ADMIN[page].EDIT}/${id}`);
    };

    return (
        <Box>
            <Box sx={styles.imageContainer}>
                <Image src={image?.file} />
            </Box>
            <Text sx={styles.date}>{getDate(createdAt)}</Text>
            <Heading sx={styles.title}>{title}</Heading>
            <Text sx={styles.description}>{getShortDescription(paragraphs[0])}</Text>
            <HStack spacing={6}>
                <Button onClick={edit} colorScheme="blue" variant="outline" size="sm" bg="white">
                    Edit
                </Button>
                <Button onClick={handleClickDelete} variant="link" colorScheme="red" size="sm">
                    Delete
                </Button>
            </HStack>
        </Box>
    );
};
