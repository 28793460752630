import { extendTheme } from "@chakra-ui/react";

import { components } from "./components";

const colors = {
    red: {
        500: "#E3474B",
    },
    blue: {
        900: "#021C56",
    },
    green: {
        500: "#30AA51",
    },
    gray: {
        50: "#F0F4F5",
        200: "#E4E5EB",
        300: "#8D91A0",
        400: "#6B6B6B",
        500: "#6C748E",
    },
    orange: {
        500: "#F9A208",
    },
};

const sizes = {
    container: {
        xl: "1200px",
    },
};

const fonts = {
    heading: "Inter",
    body: "Inter",
};

const breakpoints = {
    sm: "48em",
    md: "62em",
    lg: "80em",
    xl: "96em",
    "2xl": "1536px",
};

export const theme = extendTheme({ colors, sizes, fonts, components, breakpoints });
