import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    root: {},
    item: {
        alignItems: "center",
    },
    value: {
        ...typography(20, 24, 700),
    },
    icon: {
        ml: 1,
    },
    coinIcon: {
        w: "18px",
        h: "19px",
    },
    thunderIcon: {
        w: "11px",
        h: "21px",
    },
};
