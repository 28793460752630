import { Heading, SimpleGrid, Spinner } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { Item } from "containers/Admin/Quiz/PacksGrid/Item";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetUserQuizzesQuery } from "../api";
import { getQuizTypeFromURL } from "./utils";

export const Games: FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const quizType = getQuizTypeFromURL(pathname);
    const { data, isLoading } = useGetUserQuizzesQuery(quizType);

    const handleItemClick = (id: number) => {
        navigate(`${ROUTES.DASHBOARD.GAMES[`${quizType}_QUIZ`]}/${id}`);
    };

    return (
        <>
            <Heading mb={45}>Quiz</Heading>
            {isLoading && <Spinner size="xl" />}
            {data && (
                <SimpleGrid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" columnGap={14} rowGap={8}>
                    {data.map((pack) => (
                        <Item {...pack} key={pack.id} onClick={() => handleItemClick(pack.id)} />
                    ))}
                </SimpleGrid>
            )}
        </>
    );
};
