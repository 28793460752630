import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    List,
    ListIcon,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Select,
    Stack,
    Text,
} from "@chakra-ui/react";
import { ReactComponent as UploadIcon } from "assets/images/blog/upload.svg";
import { ReactComponent as PlayIcon } from "assets/images/quiz/play.svg";
import { Video } from "components/Video";
import { PostImageType } from "containers/Admin/ArticlesPage/Posts/types";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { PresentationType } from "types/question";

import { useUploadFileMutation } from "./api";
import { styles } from "./styles";
import { Props } from "./types";
import { getFileNames } from "./utils";

export const FileInput: FC<Props> = ({
    value,
    name,
    previouslyUploaded,
    multiple,
    attachmentOwnerType = "article",
}) => {
    const [uploadFile, { isLoading }] = useUploadFileMutation();
    const [present, setPresent] = useState<string>();
    const { setValue, watch } = useFormContext();

    useEffect(() => {
        watch(name);
    }, []);

    if (previouslyUploaded && multiple) {
        throw new Error("Multiple file input shouldn't have options");
    }

    const onDrop = useCallback(
        (files: Array<File>) => {
            files.forEach((file) => {
                const type: PresentationType = file.type.split("/")[0] === "image" ? "IMAGE" : "VIDEO";
                uploadFile({ file, type, objectType: attachmentOwnerType }).then((res: any) => {
                    let { data } = res;
                    if (multiple) {
                        if (Array.isArray(value)) {
                            data = value.concat(data);
                        } else if (value) {
                            data = [data, value];
                        }
                    }
                    setValue(name, data);
                });
            });
        },
        [value, multiple]
    );

    const removeFile = (fileName: string) => {
        let newInputValue: PostImageType | PostImageType[] | undefined = undefined;
        if (multiple && Array.isArray(value)) {
            newInputValue = value.filter((f) => f.fileName !== fileName);
        }
        setValue(name, newInputValue);
    };

    const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const fileName = event.target.value;
        const selectedFile = previouslyUploaded?.find((f) => f.fileName === fileName);
        const fieldValue = multiple ? [selectedFile] : selectedFile;
        if (fieldValue) {
            setValue(name, fieldValue);
        }
    };

    const playSelected = () => {
        if (value && !Array.isArray(value)) {
            if (!value.fileName.endsWith(".mp4")) {
                throw new Error("File must be a video");
            }
            setPresent(value.file);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const fileNames = getFileNames(value);

    return (
        <Stack spacing={6}>
            <Box sx={styles.container}>
                <Input {...getInputProps()} size="md" />
                <HStack spacing={3} {...getRootProps()}>
                    <Button colorScheme="blue" variant="ghost" size="md" isLoading={isLoading} sx={styles.uploadButton}>
                        Upload files...
                    </Button>
                    <HStack spacing={1}>
                        <UploadIcon />
                        <Text sx={styles.dropInfo}>Drop files here</Text>
                    </HStack>
                </HStack>
                {fileNames.length > 0 && (
                    <List spacing={3} mt={5} px={2}>
                        {fileNames.map((fileName, i) => (
                            <ListItem as={HStack} key={i}>
                                <ListIcon as={CheckIcon} color="#1676F3" />
                                <Text sx={styles.fileName}>{fileName}</Text>
                                <IconButton
                                    onClick={() => removeFile(fileName)}
                                    aria-label="Remove file"
                                    icon={<CloseIcon h="10px" w="10px" />}
                                    variant="ghost"
                                    size="sm"
                                    sx={styles.removeButton}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
            {previouslyUploaded && !Array.isArray(value) && (
                <>
                    <HStack spacing={2}>
                        <Box sx={styles.line} />
                        <Text sx={styles.or}>OR</Text>
                        <Box sx={styles.line} />
                    </HStack>
                    <FormControl>
                        <FormLabel>Select previously added</FormLabel>
                        <Select bg="white" name={name} value={value?.fileName} onChange={onSelectChange}>
                            {previouslyUploaded.map((option) => (
                                <option key={option.file} value={option.fileName}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        colorScheme="blue"
                        sx={styles.playButton}
                        iconSpacing="14px"
                        size="md"
                        rightIcon={<PlayIcon />}
                        onClick={playSelected}
                    >
                        Preview media
                    </Button>
                </>
            )}
            <Modal isOpen={Boolean(present)} onClose={() => setPresent(undefined)} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={0}>
                        <Box h={300}>
                            <Video
                                options={{
                                    controls: true,
                                    sources: [{ src: present! }],
                                }}
                            />
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Stack>
    );
};
