import { typography } from "../mixins";

export const Button = {
    defaultProps: {
        size: "lg",
    },
    baseStyle: {
        ...typography([12, 13], [15, 16], 600),
    },
    sizes: {
        lg: {
            ...typography(16, 19, 700),
            height: "50px",
            px: 6,
        },
        md: {
            ...typography(13, 16, 600),
            h: 9,
        },
    },
    variants: {
        ghost: {
            bg: "white",
            boxShadow:
                "0px 27px 33px rgba(6, 6, 6, 0.07), 0px 8.13971px 9.94853px rgba(6, 6, 6, 0.0456112), 0px 3.38082px 4.13211px rgba(6, 6, 6, 0.035), 0px 1.22278px 1.4945px rgba(6, 6, 6, 0.0243888)",
        },
        block: {
            width: "100%",
        },
    },
};
