import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    grid: {
        mt: 6,
        gridTemplateColumns: "repeat(auto-fill, minmax(202px, 1fr))",
    },
    item: {
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        borderRadius: 12,
        border: "1px solid #E4E4E4",
        overflow: "hidden",
        padding: 4,
        backgroundColor: "white",
        width: "100%",
        height: 234,
        textAlign: "center",
    },
    price: {
        position: "absolute",
        top: 0,
        left: 0,
        borderBottomRightRadius: 50,
        width: 92,
        height: 31,
        backgroundColor: "blue.900",
        alignItems: "center",
        justifyContent: "center",
    },
    coinIcon: {
        h: "14px",
        w: "14px",
        marginLeft: 1,
    },
    label: {
        ...typography(12, 15, 500),
        color: "gray.500",
        mb: "10px",
    },
    getButton: {
        color: "#6A707B",
    },
};
