import { Box, Flex, Text } from "@chakra-ui/react";
import { ProgressBar } from "components/ProgressBar";
import { useCountDown } from "hooks";
import { FC, useEffect } from "react";

import { styles } from "./styles";

type Props = {
    onTimeOut: () => void;
    timeLeft: number;
    totalTime: number;
    isQuizFinished?: boolean;
};

export const Timer: FC<Props> = ({ onTimeOut, timeLeft, totalTime, isQuizFinished }) => {
    const { counterValue, run, pause } = useCountDown({ from: timeLeft, onTimeOut });

    useEffect(() => {
        run();
    }, []);

    useEffect(() => {
        pause();
    }, [isQuizFinished]);

    return (
        <Flex sx={styles.root}>
            <ProgressBar total={Math.floor(totalTime / 60)} current={Math.floor((totalTime - counterValue) / 60)} />
            <Box sx={styles.info}>
                <Text sx={styles.goal}>To turn off the timer</Text>
                <Text sx={styles.shouldDo}>become a premium user</Text>
            </Box>
        </Flex>
    );
};
