import { DeepPartial } from "@reduxjs/toolkit";

import { Paragraph, PostType } from "../../Posts/types";

export const initialPost: DeepPartial<PostType> = {
    title: "",
    paragraphs: [{ images: [], text: "" }],
};

export const emptyParagraph: Omit<Paragraph, "id"> = {
    images: [],
    text: "",
};
