import { Button, Heading, ModalBody, ModalFooter, Stack, Text } from "@chakra-ui/react";
import { FC } from "react";

import { modalFooter } from "../commonStyles";
import { CommonProps } from "../types";
import { styles } from "./styles";

export const SuccessfulPurchase: FC<CommonProps> = ({ onClose }: CommonProps) => {
    return (
        <>
            <ModalBody as={Stack} sx={styles.body}>
                <Heading sx={styles.heading}>Gongrats !</Heading>
                <Stack spacing={3}>
                    <Text sx={styles.info}>You have purchased an item</Text>
                    <Text sx={styles.info}>
                        We will contact you soon to explain in detail how to pick up the purchase
                    </Text>
                </Stack>
            </ModalBody>
            <ModalFooter as={Button} colorScheme="green" sx={modalFooter} onClick={onClose}>
                Got it!
            </ModalFooter>
        </>
    );
};
