/* eslint-disable indent */

import { api } from "app/api";
import { post, put } from "utils/api";

import { GoodType } from "./types";

export const injectedStoreEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getGoods: builder.query<GoodType[], void>({
            query: () => `store/admin`,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "StoreListItem" as const, id })), "StoreListItem"]
                    : ["StoreListItem"],
        }),
        getGoodByID: builder.query<GoodType, number>({
            query: (id) => `store/admin/${id}`,
            providesTags: (result) => (result ? [{ type: "Good", id: result.id }] : []),
        }),
        createGood: builder.mutation<GoodType, GoodType>({
            query: post("store/admin"),
            invalidatesTags: ["StoreListItem"],
        }),
        updateGood: builder.mutation<GoodType, GoodType>({
            query: put("store/admin"),
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: "Good", id: result.id },
                          { type: "StoreListItem", id: result.id },
                      ]
                    : [],
        }),
        deleteGood: builder.mutation<void, number>({
            query: (id) => ({
                url: `store/admin/${id} `,
                method: "DELETE",
            }),
            invalidatesTags: (result, _error, id) => (result === undefined ? [] : [{ type: "StoreListItem", id }]),
        }),
    }),
});

export const {
    useGetGoodsQuery,
    useGetGoodByIDQuery,
    useDeleteGoodMutation,
    useCreateGoodMutation,
    useUpdateGoodMutation,
} = injectedStoreEndpoints;
