import { Spinner } from "@chakra-ui/react";
import { selectToken, selectUser } from "containers/Auth/slice/selectors";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useGetUserQuery } from "../api";
import { ROUTES } from "./constants";

export const PrivateRoutesContainer: FC = () => {
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isLoading, isSuccess, isError } = useGetUserQuery(pathname, {
        skip: Boolean(user || !token),
    });

    useEffect(() => {
        if (!token || isError) {
            navigate(ROUTES.AUTH.LOGIN);
        }
    }, [token, isError, navigate]);

    return (
        <>
            {isLoading && <Spinner size="xl" />}
            {(isSuccess || user) && <Outlet />}
        </>
    );
};
