import { PostImageType } from "../../../ArticlesPage/Posts/types";

export const getFileNames = (files?: PostImageType | PostImageType[]): string[] => {
    const fileNames: string[] = [];

    if (Array.isArray(files)) {
        files.forEach(({ fileName }) => {
            fileNames.push(fileName);
        });
    } else if (files) {
        fileNames.push(files.fileName);
    }

    return fileNames;
};
