import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./consts";
import { ShowNotificationPayload } from "./types";

const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        showNotification: (state, { payload }: PayloadAction<ShowNotificationPayload>) => {
            state.template = payload.template;
            state.options = payload.options;
        },
        hideNotification: (state) => {
            state.template = null;
            state.options = null;
        },
    },
});

export const {
    reducer: notificationReducer,
    actions: { showNotification, hideNotification },
} = slice;
