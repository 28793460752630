import { freeLesson } from "assets/images/banners";
import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    freeLesson: {
        p: 8,
        background: `url("${freeLesson}") center/contain no-repeat, #FFB81E`,
    },
    title: {
        ...typography(23, 28, 700),
        color: "white",
        mb: 4,
    },
    button: {
        ...typography(13, 16, 600),
        h: 9,
        backgroundColor: "#E3474B",
    },
};
