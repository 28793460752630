import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "app/api";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { initialState } from "./constants";

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateAccessToken: (state, { payload }: PayloadAction<string>) => {
            state.token = payload;
        },
        logout: (state) => {
            state.token = "";
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        const { login, logout: logoutRequest, getUser } = api.endpoints;

        builder
            .addMatcher(login.matchFulfilled, (state, { payload }) => {
                state.token = payload.accessToken;
                state.refreshToken = payload.refreshToken;
            })
            .addMatcher(logoutRequest.matchFulfilled, (state) => {
                state.token = "";
                state.user = null;
            })
            .addMatcher(getUser.matchFulfilled, (state, { payload }) => {
                state.user = payload;
            });
    },
});

export const {
    actions: { updateAccessToken, logout },
} = authSlice;

const authPersistConfig = {
    key: "auth",
    storage: storage,
    whitelist: ["token", "user"],
};

export const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
