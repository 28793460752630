import { FC } from "react";

import { NotificationTemplate } from "../../store";
import { GoodJob } from "./GoodJob";
import { LevelReach } from "./LevelReach";
import { Looser } from "./Looser";
import { SuccessfulPurchase } from "./SuccessfulPurchase";
import { TrialTimeOut } from "./TrialTimeOut";
import { UnlockConfirmation } from "./UnlockConfirmation";
import { UnlockSent } from "./UnlockSent";

export const notificationTemplates: Record<NotificationTemplate, FC<any>> = {
    goodJob: GoodJob,
    trialTimeOut: TrialTimeOut,
    levelReach: LevelReach,
    looser: Looser,
    unlockConfirmation: UnlockConfirmation,
    unlockSent: UnlockSent,
    successfulPurchase: SuccessfulPurchase,
};
