import { Button, Flex, FlexProps, Text } from "@chakra-ui/react";
import { useAppDispatch } from "app/store/hooks";
import { showNotification } from "containers/Notification/store";
import { FC } from "react";

import { styles } from "./styles";

export const UnlockBlock: FC<FlexProps> = (props) => {
    const dispatch = useAppDispatch();

    const unlock = () => {
        dispatch(showNotification({ template: "unlockConfirmation" }));
    };

    return (
        <Flex sx={styles.container} {...props}>
            <Text sx={styles.info}>
                To unlock all functions, <br />
                become a student of Roof
            </Text>
            <Button colorScheme="orange" size="sm" sx={styles.button} onClick={unlock}>
                Unlock now
            </Button>
        </Flex>
    );
};
