import { configureStore } from "@reduxjs/toolkit";
import { api } from "app/api";
import { deletionReducer } from "containers/Admin/store/deletionSlice";
import { authReducer } from "containers/Auth/slice";
import { notificationReducer } from "containers/Notification/store";
import { persistStore } from "redux-persist";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        notification: notificationReducer,
        deletion: deletionReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});

export const persistedStore = persistStore(store);

export type ApplicationState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
