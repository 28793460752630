/* eslint-disable indent */
import { api } from "app/api";
import { QuestionType } from "types/question";
import { post, put } from "utils/api";

import { QuestionCreateRequest } from "./types";

export const injectedQuestionEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getQuestion: builder.query<QuestionType, string>({
            query: (id) => `quiz/admin/question/${id}`,
            providesTags: (result) => (result ? [{ type: "Question", id: result.id }] : ["Question"]),
        }),
        createQuestion: builder.mutation<QuestionType, QuestionCreateRequest>({
            query: post("quiz/admin/question/"),
            invalidatesTags: (quiz) =>
                quiz
                    ? [
                          { type: "Pack", id: quiz.id },
                          { type: "QuizListItem", id: quiz.id },
                      ]
                    : [],
        }),
        updateQuestion: builder.mutation<QuestionType, QuestionCreateRequest>({
            query: ({ question: { id, ...question } }) => put(`quiz/admin/question/${id}`)(question),
            invalidatesTags: (_result, _error, arg) =>
                _result !== undefined
                    ? [
                          { type: "Question", id: arg.question.id },
                          { type: "Pack", id: arg.quizId },
                          { type: "QuizListItem", id: arg.quizId },
                      ]
                    : [],
        }),
        deleteQuestion: builder.mutation<void, Record<"questionId" | "quizId", number>>({
            query: ({ questionId }) => ({
                url: `quiz/admin/question/${questionId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, error, { quizId }) =>
                error
                    ? []
                    : [
                          { type: "Pack", id: quizId },
                          { type: "QuizListItem", id: quizId },
                      ],
        }),
    }),
});

export const { useCreateQuestionMutation, useUpdateQuestionMutation, useGetQuestionQuery, useDeleteQuestionMutation } =
    injectedQuestionEndpoints;
