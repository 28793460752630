import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useAppSelector } from "app/store/hooks";
import { selectIsAdmin } from "containers/Auth/slice/selectors";
import { createElement, FC, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import { adminMenuItems, menuItems } from "./consts";
import { styles } from "./styles";

export const Menu: FC = () => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { pathname } = useLocation();
    const isAdmin = useAppSelector(selectIsAdmin);

    const items = isAdmin ? adminMenuItems : menuItems;

    return (
        <Stack spacing={8} sx={styles.menu}>
            {items.map(({ label, Icon, path, subItems }) =>
                subItems ? (
                    <Fragment key={label}>
                        <Flex
                            key={label}
                            sx={{
                                ...styles.menuItem,
                                ...(isOpen || pathname.startsWith(path) ? styles.menuItem["_hover"] : {}),
                            }}
                            justify="space-between"
                            onClick={onToggle}
                        >
                            <Flex align="center">
                                {Icon && <Icon />}
                                <Text sx={styles.menuItemLabel}>{label}</Text>
                            </Flex>
                            {createElement(isOpen ? ChevronUpIcon : ChevronDownIcon, { sx: styles.arrowIcon })}
                        </Flex>
                        <Collapse in={isOpen} animateOpacity style={{ marginTop: "0" }}>
                            <Stack spacing={1} mt={4}>
                                {subItems!.map(({ label, path, ellipsisColor }) => {
                                    const itemStyle = { ...styles.menuItem, ...styles.subItem };
                                    return (
                                        <Flex
                                            key={label}
                                            as={Link}
                                            to={path}
                                            sx={{ ...itemStyle, ...(pathname === path ? itemStyle["_hover"] : {}) }}
                                        >
                                            <Box sx={styles.ellipsis} bgColor={ellipsisColor} />
                                            <Text sx={styles.subItemLabel}>{label}</Text>
                                        </Flex>
                                    );
                                })}
                            </Stack>
                        </Collapse>
                    </Fragment>
                ) : (
                    <Flex
                        key={label}
                        sx={{ ...styles.menuItem, ...(pathname.startsWith(path) ? styles.menuItem["_hover"] : {}) }}
                        as={Link}
                        to={path}
                        onClick={onClose}
                    >
                        {Icon && <Icon />}
                        <Text sx={styles.menuItemLabel}>{label}</Text>
                    </Flex>
                )
            )}
        </Stack>
    );
};
