import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    item: {
        backgroundColor: "#E5E9F5",
        borderRadius: 8,
        w: 79,
        h: 45,
    },
    info: {
        flex: 1,
        alignSelf: "center",
    },
    label: {
        ...typography(12, 14, 500),
        color: "#7C879F",
    },
};
