import "video.js/dist/video-js.css";

import { Box } from "@chakra-ui/react";
import * as React from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";

import { styles } from "./styles";

interface IProps {
    options: VideoJsPlayerOptions;
    onPlay?: () => void;
}

export const Video: React.FC<IProps> = ({ options, onPlay }) => {
    const videoRef = React.useRef(null);

    // This seperate functional component fixes the removal of the videoelement
    // from the DOM when calling the dispose() method on a player
    const VideoHtml = () => (
        <div data-vjs-player={true}>
            <video ref={videoRef} onPlay={onPlay} className="video-js vjs-default-skin" />
        </div>
    );

    React.useEffect(() => {
        const videoElement = videoRef.current;
        let player: VideoJsPlayer;
        if (videoElement) {
            player = videojs(videoElement, options);
        }
        return () => {
            if (player) {
                player.dispose();
            }
        };
    }, [options]);

    return (
        <Box sx={styles.root}>
            <VideoHtml />
        </Box>
    );
};
