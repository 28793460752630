import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Button,
    ButtonProps,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    Stack,
    useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { FormInput } from "../../components/FormInput";
import { useQuizList } from "../context";
import { useCreateQuizMutation } from "./api";
import { CreateQuizForm } from "./types";

const FORM_ID = "add-form";

export const AddQuizButton: FC<ButtonProps> = (props) => {
    const [createQuiz, { isLoading }] = useCreateQuizMutation();
    const { type } = useQuizList();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const form = useForm<CreateQuizForm>({ mode: "onChange" });

    const onSubmit = (data: CreateQuizForm) => {
        createQuiz({ ...data, type }).then(() => {
            form.reset();
            onClose();
        });
    };

    return (
        <>
            <Button colorScheme="green" onClick={onOpen} {...props}>
                Add quiz pack
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading fontSize={18}>Add new pack</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt={6} pb={45}>
                        <FormProvider {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)} id={FORM_ID}>
                                <Stack spacing={6}>
                                    <FormInput isRequired label="Pack name" type="input" name="title" />
                                    <FormInput isRequired label="Show to" name="access" type="radio">
                                        <HStack spacing={6}>
                                            <Radio bg="white" value="FREE" colorScheme="green">
                                                All
                                            </Radio>
                                            <Radio bg="white" value="STUDENT" colorScheme="green">
                                                Students only
                                            </Radio>
                                        </HStack>
                                    </FormInput>
                                </Stack>
                            </form>
                        </FormProvider>
                    </ModalBody>
                    <ModalFooter>
                        <HStack spacing={14}>
                            <Button variant="link" colorScheme="blue" color="blue.900" size="md" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!form.formState.isDirty || !form.formState.isValid}
                                isLoading={isLoading}
                                type="submit"
                                form={FORM_ID}
                                colorScheme="green"
                                size="md"
                                h={10}
                                w={122}
                            >
                                Add
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
