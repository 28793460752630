import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    usersCount: {
        ...typography(14, 17, 700),
        color: "gray.400",
        mt: 1,
    },
};
