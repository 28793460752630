import { api } from "app/api";

import { UploadedImage, UploadMutationOptions } from "./types";

const fileEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        uploadFile: builder.mutation<UploadedImage, UploadMutationOptions>({
            query: ({ file, type, objectType }: UploadMutationOptions) => {
                const form = new FormData();
                form.append("file", file);
                form.append("type", type);

                const url = objectType === "quiz" ? "files/upload" : "blogs/image/upload";

                return {
                    url,
                    method: "POST",
                    body: form,
                };
            },
        }),
    }),
});

export const { useUploadFileMutation } = fileEndpoints;
