import { Styles } from "types";

export const styles: Styles = {
    root: {
        w: "100%",
        h: "100%",

        "& .video-js": {
            backgroundColor: "transparent",
            fontSize: 10,
            color: "white",
            w: "100%",
            h: "100%",
        },

        "& .vjs-big-play-button": {
            lineHeight: "40px",
            h: 10,
            w: 10,
            borderRadius: "50%",
            borderWidth: 0,
            left: "50%",
            top: "50%",
            ml: "-20px",
            mt: "-20px",
        },

        "& .vjs-control-bar": {
            width: "auto",
            m: 2,
            borderRadius: 8,
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            backdropFilter: "blur(16px)",
            alignItems: "center",
        },

        "& .vjs-big-play-button, & .vjs-menu-content": {
            backgroundColor: "rgba(255, 255, 255, 0.15)",
        },

        "& .vjs-slider": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
        },

        "& .vjs-volume-level, & .vjs-slider-bar": {
            color: "#FB3A3A",
        },

        "& .vjs-volume-level, & .vjs-play-progress, & .vjs-slider-bar": {
            background: "#FB3A3A",
        },

        "& .vjs-load-progress": {
            background: "rgba(255, 255, 255, 0.5)",

            "& div": {
                background: "rgba(255, 255, 255, 0.5)",
            },
        },

        "& .vjs-paused.vjs-has-started .vjs-big-play-button": {
            display: "block",
        },

        "& .vjs-play-control, & .vjs-play-control:focus": {
            outline: 0,
        },
    },
};
