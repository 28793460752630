import { createContext, FC, useContext } from "react";

import { QuizListContextType } from "./types";

export const QuizListContext = createContext<QuizListContextType>({} as QuizListContextType);

export const QuizListProvider: FC<Record<"value", QuizListContextType>> = ({ value, children }) => {
    return <QuizListContext.Provider value={value}>{children}</QuizListContext.Provider>;
};

export const useQuizList = () => {
    const quizListContextValue = useContext(QuizListContext);

    if (!quizListContextValue) {
        throw new Error("useQuizList must be used within QuizListProvider");
    }

    return quizListContextValue;
};
