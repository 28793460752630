import { compose } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { history } from "app";
import { LoginFormType, LoginResponse } from "containers/Auth/ui/blocks/RightSide/LoginForm/types";
import { RegisterFormRequest } from "containers/Auth/ui/blocks/RightSide/RegisterForm/types";
import { User } from "types/user";

import { getDefaultRoute } from "../../containers/Layout/utils";
import { post } from "../../utils/api";
import { ROUTES } from "../routes/constants";
import { baseQueryWithReAuth } from "./baseQuery";

export const api = createApi({
    baseQuery: baseQueryWithReAuth,
    tagTypes: [
        "User",
        "NEWS",
        "BLOG",
        "CLASSIC",
        "VIDEO",
        "UserQuizListItem",
        "UserQuizDetails",
        "QuizListItem",
        "Pack",
        "Question",
        "QuestionForUser",
        "StoreListItem",
        "Good",
        "Student",
        "Products",
    ],
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginFormType>({
            query: post("public/users/login"),
            async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
                queryFulfilled.then(() => {
                    const getRole = (response: any) => response.data.role;
                    dispatch(api.endpoints.getUser.initiate(undefined, { forceRefetch: true })).then(
                        compose(history.push, getDefaultRoute, getRole)
                    );
                });
            },
        }),
        logout: builder.mutation({
            queryFn: () => {
                return Promise.resolve().then(() => ({ data: "" }));
            },
            async onQueryStarted(_arg, { queryFulfilled }) {
                queryFulfilled.then(() => {
                    history.push(ROUTES.AUTH.LOGIN);
                });
            },
        }),
        register: builder.mutation<User, RegisterFormRequest>({
            query: post("public/users/sign-up"),
            async onQueryStarted(_arg, { queryFulfilled }) {
                queryFulfilled.then(() => {
                    history.push(ROUTES.AUTH.LOGIN);
                });
            },
        }),
        getUser: builder.query<User, unknown>({
            query: () => "users/user/current",
            providesTags: ["User"],
        }),
    }),
});

export const { useLoginMutation, useLogoutMutation, useRegisterMutation, useGetUserQuery } = api;
