import { Button, Text } from "@chakra-ui/react";
import { FC } from "react";

import { Banner } from "../Banner";
import { styles } from "./styles";

export const FreeLesson: FC = () => {
    return (
        <Banner sx={styles.freeLesson}>
            <Text sx={styles.title}>
                Join to
                <br />
                free lesson
                <br />
                and meet us
            </Text>
            <Button colorScheme="red" size="md" sx={styles.button}>
                Join to free lesson
            </Button>
        </Banner>
    );
};
