import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { useAppSelector } from "app/store/hooks";
import { isUserAdmin, selectUser } from "containers/Auth/slice/selectors";
import { FC } from "react";

import { styles } from "./styles";
import { getFullName } from "./utils";

export const UserGreetings: FC = () => {
    const user = useAppSelector(selectUser);
    const isAdmin = isUserAdmin(user);

    const openedApp = isAdmin ? "admin panel" : "dashboard";

    if (!user) return null;

    return (
        <Flex>
            <Avatar name={getFullName(user)} sx={styles.avatar} />
            <Box>
                <Text sx={styles.name}>
                    Hi, <span>{user.name}</span>
                </Text>
                <Text sx={styles.greeting}>Welcome to your {openedApp}</Text>
            </Box>
        </Flex>
    );
};
