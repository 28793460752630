import { Flex, Heading, Spinner, Tab, TabList, Tabs } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { QuizType } from "containers/Dashboard/Games/store";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AddQuizButton } from "./AddQuizButton";
import { useGetQuizzesQuery } from "./api";
import { QuizListProvider } from "./context";
import { PacksGrid } from "./PacksGrid";

const tabs = [
    { label: "Classic", component: "", hash: "#classic", type: "CLASSIC" as QuizType },
    { label: "Video", component: "", hash: "#video", type: "VIDEO" as QuizType },
];

export const Quiz: FC = () => {
    const { pathname, hash } = useLocation();
    const navigate = useNavigate();

    const handleItemClick = (id: number) => {
        navigate(`${ROUTES.ADMIN.QUIZ.PACK}/${id}`);
    };

    const handleTabChange = (index: number) => {
        const hash = tabs[index].hash;
        navigate({ pathname, hash });
    };

    const activeTabIndex = hash ? tabs.findIndex((t) => t.hash === hash) : 0;
    const { type } = tabs[activeTabIndex];

    const { data, isLoading } = useGetQuizzesQuery(type);

    return (
        <QuizListProvider value={{ type }}>
            <Flex justify="space-between">
                <Heading>Quiz pack</Heading>
                <AddQuizButton />
            </Flex>
            <Tabs onChange={handleTabChange} index={activeTabIndex} colorScheme="green" mt={2}>
                <TabList mb={8}>
                    {tabs.map(({ label }) => (
                        <Tab key={label}>{label}</Tab>
                    ))}
                </TabList>
                {isLoading && <Spinner size="xl" />}
                {data && <PacksGrid packs={data} onItemClick={handleItemClick} />}
            </Tabs>
        </QuizListProvider>
    );
};
