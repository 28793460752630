import { Box, Button, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { coin, thunder } from "assets/images/header/achievements";
import { FC } from "react";

import { CheckAnswerRequest } from "../../../types";
import { styles } from "./styles";

interface Props {
    onSkip: (requestBody: CheckAnswerRequest) => void;
    isAnswered: boolean;
    quizId: number;
    questionId: number;
    getNextQuestion: () => void;
    isLast?: boolean;
}

export const BottomSide: FC<Props> = ({ isAnswered, onSkip, quizId, questionId, getNextQuestion, isLast }) => {
    const handleSkip = () => {
        if (isAnswered) {
            getNextQuestion();
            return;
        }

        const requestBody: CheckAnswerRequest = {
            QuizId: quizId,
            QuestionId: questionId,
            isSkipped: true,
            Answer: "",
        };

        onSkip(requestBody);
    };

    return (
        <Flex sx={styles.root}>
            <Box>
                <Text as={HStack} spacing={3} sx={styles.hint}>
                    <Flex>
                        True answer +25
                        <Image src={thunder} sx={styles.thunder} />
                    </Flex>
                    <HStack spacing={1}>
                        <span>+10</span>
                        <Image src={coin} sx={styles.coin} />
                    </HStack>
                </Text>
                <Text as={Flex} sx={styles.hint}>
                    Wrong answer -10
                    <Image src={thunder} sx={styles.thunder} />
                </Text>
            </Box>
            {(!isLast || !isAnswered) && (
                <Button onClick={handleSkip} colorScheme="green" variant="outline" sx={styles.skipButton}>
                    {isAnswered ? "Next" : "Skip"}
                </Button>
            )}
        </Flex>
    );
};
