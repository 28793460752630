import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    card: {
        borderRadius: 8,
        backgroundColor: "white",
        pt: 4,
        px: 4,
    },
    question: {
        ...typography(14, 17, 500),
        color: "blue.900",
        h: 34,
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    answer: {
        ...typography(14, 17, 500),
        color: "green.500",
        mt: 4,
        mb: "14px",
    },
    footer: {
        borderTop: "1px solid rgba(146, 154, 182, 0.1)",
        justifyContent: "flex-end",
        py: "10px",
        px: 4,
        mx: "-16px",
    },
};
