import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    body: {
        pt: 8,
        pb: 116,
        alignItems: "center",
        textAlign: "center",
    },
    emoji: {
        h: 70,
        w: 70,
    },
    info: {
        ...typography(14, 17, 600),
        maxW: 200,
        color: "gray.500",
    },
};
