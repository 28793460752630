import { FC } from "react";

import { DashboardLayout } from "../Dashboard/Layout";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";

export const AdminLayout: FC = () => {
    return (
        <>
            <DeleteConfirmationModal />
            <DashboardLayout />
        </>
    );
};
