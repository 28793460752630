import { Box, Flex, Heading, Spinner, Stack, Text } from "@chakra-ui/react";
import { api } from "app/api";
import { ROUTES } from "app/routes/constants";
import { useAppDispatch } from "app/store/hooks";
import { showNotification } from "containers/Notification/store";
import { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getQuizType } from "utils/url";

import { useCheckAnswerMutation, useGetNextQuestionQuery, useGetUserQuizByIdQuery } from "../../api";
import { CheckAnswerRequest } from "../../types";
import { Timer } from "../Timer";
import { BottomSide } from "./BottomSide";
import { InitialView } from "./InitialView";
import { Question } from "./Question";
import { styles } from "./styles";

export const QuizFrame: FC = () => {
    const { id } = useParams<Record<"id", string>>();
    const { pathname } = useLocation();
    const { data: quiz, isLoading: isQuizLoading } = useGetUserQuizByIdQuery(id, { skip: !id });
    const {
        data: question,
        isLoading: isQuestionLoading,
        refetch: refetchNextQuestion,
    } = useGetNextQuestionQuery(quiz?.id, {
        skip: !quiz?.id || ["NOT_STARTED", "FINISHED"].some((s) => s === quiz.quizStatus),
    });
    const [
        checkAnswer,
        { isLoading: isChecking, data: checkingResult, reset: resetCheckingResult, error: checkAnswerError },
    ] = useCheckAnswerMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleTimeOut = () => {
        if (quiz) {
            dispatch(api.endpoints.getUser.initiate(undefined, { forceRefetch: true }));
            dispatch(
                showNotification({
                    template: "trialTimeOut",
                    options: {
                        ...quiz,
                        onClose: goToQuizList,
                    },
                })
            );
        }
    };

    useEffect(() => {
        resetCheckingResult();
    }, [question?.Question.id]);

    useEffect(() => {
        if ((checkAnswerError as any)?.data?.code === "error.quiz.timer-expired") {
            handleTimeOut();
        }
    }, [checkAnswerError]);

    const getNextQuestion = () => {
        return refetchNextQuestion();
    };

    const goToQuizList = () => {
        const urlOnClose =
            getQuizType(pathname) === "VIDEO" ? ROUTES.DASHBOARD.GAMES.VIDEO_QUIZ : ROUTES.DASHBOARD.GAMES.CLASSIC_QUIZ;

        navigate(urlOnClose);
    };

    const handleAnswer = (requestBody: CheckAnswerRequest) => {
        return checkAnswer(requestBody).then((response: any) => {
            if (response.data?.isLeveledUp) {
                dispatch(
                    showNotification({
                        template: "levelReach",
                        options: {
                            level: response.data.level,
                        },
                    })
                );
            } else if (response.data?.isFinished) {
                const { coins, points, correctCount } = response.data;
                dispatch(
                    showNotification({
                        template: "goodJob",
                        options: {
                            onClose: goToQuizList,
                            answered: correctCount,
                            questionsCount: quiz?.questionCount,
                            coins: coins ?? 0,
                            points: points ?? 0,
                        },
                    })
                );
            } else if (requestBody.isSkipped) {
                return getNextQuestion();
            }
        });
    };

    return (
        <>
            <Flex sx={styles.header}>
                <Box>
                    <Heading>Quiz</Heading>
                    {quiz && (
                        <Text sx={styles.correctAnswers}>
                            Answered {quiz.answeredCount || 0} of {quiz.questionCount}
                        </Text>
                    )}
                </Box>
                <Stack sx={styles.successMessage}>
                    {checkingResult?.threeInRow && (
                        <>
                            <div>😄</div>
                            <div>Əla, üç düzgün cavab ard-arda!</div>
                        </>
                    )}
                </Stack>
                {quiz?.timer && quiz?.timerTotal && (
                    <Timer
                        timeLeft={quiz.timer}
                        totalTime={quiz.timerTotal}
                        onTimeOut={handleTimeOut}
                        isQuizFinished={checkingResult?.isFinished}
                    />
                )}
            </Flex>
            {isQuizLoading && <Spinner size="xl" />}
            {quiz && (
                <Box sx={styles.container}>
                    <Box sx={styles.frame}>
                        {quiz.quizStatus === "NOT_STARTED" ? (
                            <InitialView id={quiz.id} />
                        ) : (
                            <>
                                {isQuestionLoading && <Spinner size="xl" />}
                                {question && (
                                    <Question
                                        {...question}
                                        quizId={quiz.id}
                                        checkAnswer={handleAnswer}
                                        isChecking={isChecking}
                                        isAnswered={Boolean(checkingResult)}
                                        isCorrect={checkingResult?.isCorrect}
                                        correctAnswer={checkingResult?.correctAnswer?.body}
                                        achievedPoints={quiz.points}
                                        achievedCoins={quiz.coins}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                    {question && (
                        <BottomSide
                            quizId={quiz.id}
                            questionId={question.Question.id!}
                            isAnswered={Boolean(checkingResult)}
                            getNextQuestion={getNextQuestion}
                            onSkip={handleAnswer}
                            isLast={question.IsLast}
                        />
                    )}
                </Box>
            )}
        </>
    );
};
