import { QuestionType } from "types/question";

import { QuestionFormType } from "./types";

export const initialAnswerValue = " ";

export const getDefaultValues = (question?: QuestionType): QuestionFormType => ({
    body: question?.body ?? "",
    type: question?.answers && question.answers.length > 1 ? "multi" : "text",
    correctAnswer: question?.answers.find((a) => a.isCorrect)?.body ?? "",
    answers: question?.answers.map((a) => a.body) ?? new Array(4).fill(initialAnswerValue),
    file: question?.file,
});
