import { HorizontalScroll } from "components/HorizontalScroll";
import { FC } from "react";

import { Card } from "./Card";
import { cards } from "./consts";

export const GamesCards: FC = () => {
    return (
        <HorizontalScroll spacing={8} mt="45px" mb={24} alignItems="stretch">
            {cards.map((card, i) => (
                <Card key={i} {...card} />
            ))}
        </HorizontalScroll>
    );
};
