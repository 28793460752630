import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { useAppDispatch } from "app/store/hooks";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionType } from "types/question";

import { requestDeletion } from "../../../store/deletionSlice";
import { useDeleteQuestionMutation } from "../../QuestionEditor/api";
import { PackTypeForAdmin } from "../../types";
import { styles } from "./styles";

type Props = QuestionType & {
    quiz: PackTypeForAdmin;
};

export const QuestionCard: FC<Props> = ({ id, body, answers, quiz }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [deleteQuestion] = useDeleteQuestionMutation();

    const edit = () => {
        navigate(`/admin/quiz/${quiz.id}/question/edit/${id}`);
    };

    const handleDelete = () => {
        if (id && quiz.id) {
            dispatch(
                requestDeletion({
                    onConfirm: () => deleteQuestion({ questionId: id, quizId: quiz.id }),
                    entityType: "question",
                })
            );
        }
    };

    const correctAnswer = answers.find(({ isCorrect }) => isCorrect)?.body ?? "";

    const isQuizActive = quiz.status === "ACTIVE";

    return (
        <Box sx={styles.card}>
            <Text sx={styles.question}>{body}</Text>
            <Text sx={styles.answer}>{correctAnswer}</Text>
            <HStack spacing={6} sx={styles.footer}>
                <Button onClick={edit} colorScheme="blue" variant="outline" size="md" disabled={isQuizActive}>
                    Edit
                </Button>
                <Button onClick={handleDelete} colorScheme="red" variant="link" size="md" disabled={isQuizActive}>
                    Delete
                </Button>
            </HStack>
        </Box>
    );
};
