import { SimpleGrid, Spinner } from "@chakra-ui/react";
import { FC } from "react";

import { useGetProductsQuery } from "./api";
import { Item } from "./Item";
import { styles } from "./styles";

export const ItemsGrid: FC = () => {
    const { data, isLoading } = useGetProductsQuery();

    return (
        <>
            {isLoading && <Spinner size="xl" />}
            {data && (
                <SimpleGrid sx={styles.grid} spacing={8}>
                    {data.map((item, i) => (
                        <Item key={i} {...item} />
                    ))}
                </SimpleGrid>
            )}
        </>
    );
};
