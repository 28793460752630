import { QuestionType } from "types/question";

import { QuestionFormType } from "./types";

export const transformRawFormData = (data: QuestionFormType): QuestionType => {
    let answers: QuestionType["answers"];
    if (data.type === "multi") {
        answers = data.answers.map((body) => ({
            body,
            isCorrect: body === data.correctAnswer,
        }));
    } else {
        answers = [{ body: data.correctAnswer, isCorrect: true }];
    }

    return {
        answers,
        file: data.file,
        body: data.body,
    };
};
