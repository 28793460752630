import { Box, Button, Flex, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { useLoginMutation } from "app/api";
import { ROUTES } from "app/routes/constants";
import { FormInput } from "components/FormInput";
import { Logo } from "components/Logo";
import { useURLParam } from "hooks";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import { formProps } from "./consts";
import { styles } from "./styles";
import { LoginFormType } from "./types";

export const LoginForm: FC = () => {
    const form = useForm<LoginFormType>(formProps);
    const [login, { isLoading }] = useLoginMutation();
    const isAdmin = useURLParam<boolean>("admin");

    const header = isAdmin ? "Admin login page" : "Login to your account";

    const formView = (
        <form onSubmit={form.handleSubmit(login)}>
            <Heading sx={styles.header}>{header}</Heading>
            <FormProvider {...form}>
                <Stack spacing={5} sx={styles.form}>
                    <FormInput label="Email" name="email" />
                    <FormInput label="Password" name="password" type="password" />
                </Stack>
            </FormProvider>
            <Box sx={styles.forgotPasswordContainer}>
                <Link as={RouterLink} to={ROUTES.AUTH.SIGN_UP} sx={styles.forgotPassword}>
                    Forgot password?
                </Link>
            </Box>
            <Button colorScheme="green" isLoading={isLoading} type="submit" sx={styles.submitButton}>
                Login
            </Button>
        </form>
    );

    return (
        <>
            <Flex sx={styles.formContainer}>
                <Logo sx={styles.logo} />
                <Text sx={styles.welcome}>Welcome</Text>
                {formView}
            </Flex>
            {!isAdmin && (
                <Text sx={styles.bottomLink}>
                    Dont have an account?{" "}
                    <Link as={RouterLink} to={ROUTES.AUTH.SIGN_UP}>
                        Join free today
                    </Link>
                </Text>
            )}
        </>
    );
};
