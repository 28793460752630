import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    addNewBlockButton: {
        ...typography(16, 19, 700),
        borderColor: "gray.300",
        w: "100%",
        mt: 16,
    },
};
