import { academy } from "assets/images/banners";
import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    academy: {
        pl: 142,
        pt: 8,
        background: `url("${academy}") center/contain no-repeat, #9EB0BD`,
    },
    title: {
        ...typography(23, 27, 700),
        color: "white",
        mb: 4,
    },
    button: {
        ...typography(13, 16, 600),
        h: 9,
        backgroundColor: "blue.900",

        _hover: {
            backgroundColor: "blue.800",
        },
    },
};
