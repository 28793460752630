import { useEffect, useRef } from "react";

export const useEventHandler = <K extends keyof DocumentEventMap>(
    event: K,
    handler: (event: DocumentEventMap[K]) => void
): void => {
    const handlerRef = useRef(handler);

    useEffect(() => {
        const cleanUp = () => {
            document.removeEventListener(event, handlerRef.current);
        };
        cleanUp();

        handlerRef.current = handler;
        document.addEventListener(event, handlerRef.current);

        return cleanUp;
    }, [handler]);
};
