import { Box, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

import { styles } from "./styles";

export const Banner: FC<BoxProps> = ({ children, sx, ...props }) => {
    return (
        <Box sx={{ ...styles.banner, ...sx }} {...props}>
            {children}
        </Box>
    );
};
