import { Box, Image } from "@chakra-ui/react";
import { Video } from "components/Video";
import { FC, memo } from "react";
import { QuestionType } from "types/question";
import { VideoJsPlayerOptions } from "video.js";

import { styles } from "./styles";

export const Presentation: FC<QuestionType["file"]> = memo(({ file, fileName, type }) => {
    const videoOptions: VideoJsPlayerOptions = {
        controls: true,
        sources: [{ src: file }],
    };

    type ??= fileName.endsWith(".png") ? "IMAGE" : "VIDEO";

    return (
        <Box sx={styles.container}>
            {type === "IMAGE" ? <Image src={file} sx={styles.image} /> : <Video options={videoOptions} />}
        </Box>
    );
});
