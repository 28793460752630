import { typography } from "theme/mixins";
import { Styles } from "types";

import { commonStyles } from "../commonStyles";

export const styles: Styles = {
    ...commonStyles,
    header: {
        ...typography(30, 36, 700),
        mt: "2px",
    },
    forgotPasswordContainer: {
        mt: 5,
        textAlign: "right",
    },
    forgotPassword: {
        ...typography(14, 16),
        color: "blue.700",
    },
    submitButton: {
        ...commonStyles.submitButton,
        mt: 54,
    },
    bottomLink: {
        ...commonStyles.bottomLink,
        mb: 7,
    },
    formContainer: {
        ...commonStyles.formContainer,
        maxHeight: 608,
    },
};
