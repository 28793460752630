import { Global } from "@emotion/react";
import interBold from "assets/fonts/Inter-Bold.ttf";
import interMedium from "assets/fonts/Inter-Medium.ttf";
import interRegular from "assets/fonts/Inter-Regular.ttf";
import interSemiBold from "assets/fonts/Inter-SemiBold.ttf";

export const Fonts = () => (
    <Global
        styles={`
            @font-face {
              font-family: "Inter";
              src: url("${interRegular}");
              font-weight: 400;
              font-style: normal;
            }
            
            @font-face {
              font-family: "Inter";
              src: url("${interMedium}");
              font-weight: 500;
              font-style: normal;
            }
            
            @font-face {
              font-family: "Inter";
              src: url("${interSemiBold}");
              font-weight: 600;
              font-style: normal;
            }
            @font-face {
              font-family: "Inter";
              src: url("${interBold}");
              font-weight: 700;
              font-style: normal;
            }
        `}
    />
);
