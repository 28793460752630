import { api } from "app/api";
import { QuizType } from "containers/Dashboard/Games/store";
import { addURLParam } from "utils/url";

import { PacksListItem, PackTypeForAdmin } from "./types";

export const injectedQuizListEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getQuizzes: builder.query<PacksListItem[], QuizType>({
            query: (type) => `quiz/admin/${addURLParam({ type })}`,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "QuizListItem" as const, id })), "QuizListItem"]
                    : ["QuizListItem"],
        }),
        deletePack: builder.mutation<void, PackTypeForAdmin["id"]>({
            query: (id) => ({
                url: `quiz/admin/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["QuizListItem"],
        }),
    }),
});

export const { useGetQuizzesQuery, useDeletePackMutation } = injectedQuizListEndpoints;
