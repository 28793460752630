import { Heading, Stack, Text } from "@chakra-ui/react";

import { AddQuizButton } from "../../AddQuizButton";
import { styles } from "./styles";

export const EmptyGrid = () => (
    <Stack sx={styles.container}>
        <Heading sx={styles.heading}>There's nothing here yet</Heading>
        <Text sx={styles.hint}>Click on button to add</Text>
        <AddQuizButton mt="24px !important" />
    </Stack>
);
