import { Stack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { FormInput } from "../../components/FormInput";
import { GoodType } from "../types";
import { FORM_ID } from "./consts";

type Props = {
    good?: GoodType;
    onSubmit: (data: GoodType) => void;
};

export const Form = ({ good, onSubmit }: Props) => {
    const form = useForm<GoodType>({ defaultValues: good });

    return (
        <FormProvider {...form}>
            <Stack spacing={6} maxW={379} as="form" id={FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
                <FormInput name="name" type="textarea" label="Product name" />
                <FormInput name="price" type="input" label="Price (in coins)" />
                <FormInput name="quantity" type="input" label="Pcs in stock" />
                <FormInput name="file" type="file" label="Photo of product" />
            </Stack>
        </FormProvider>
    );
};
