import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    input: {
        backgroundColor: "white",

        _placeholder: {
            ...typography(14, 17, 500),
            color: "gray.200",
            fontStyle: "italic",
        },
    },
};
