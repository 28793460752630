import { useEffect, useRef, useState } from "react";

type Options = {
    from: number;
    onTimeOut: () => void;
};

export const useCountDown = ({ from, onTimeOut }: Options) => {
    const [counterValue, setCounterValue] = useState(from);
    const timer = useRef<NodeJS.Timer>();

    const cleanUp = (): void => {
        if (timer.current) {
            clearInterval(timer.current);
        }
    };

    const run = (): void => {
        cleanUp();
        setCounterValue(from);
        timer.current = setInterval(() => {
            setCounterValue((previousState) => previousState - 1);
        }, 1000);
    };

    useEffect(() => cleanUp, []);

    useEffect(() => {
        if (counterValue === 0) {
            onTimeOut();
            cleanUp();
        }
    }, [counterValue]);

    return { counterValue, run, pause: cleanUp };
};
