import { api } from "app/api";
import { Product } from "types/product";

export const injectedStoreEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query<Array<Product>, void>({
            query: () => `store/items`,
            providesTags: ["Products"],
        }),
        purchase: builder.mutation<void, number>({
            query: (id) => ({
                url: `store/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Products", "User"],
        }),
    }),
});

export const { useGetProductsQuery, usePurchaseMutation } = injectedStoreEndpoints;
