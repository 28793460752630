import { TextProps } from "@chakra-ui/react";
import { FC } from "react";

import { BaseTextGradient } from "./BaseTextGradient";

export const Level: FC<Record<"value", number> & TextProps> = ({ value, ...textProps }) => {
    return (
        <BaseTextGradient background="linear-gradient(270deg, #B7F2F9 -23.18%, #3553CE 118.75%)" {...textProps}>
            {value}
        </BaseTextGradient>
    );
};
