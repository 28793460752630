import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, HStack, Input as ChakraInput, Text } from "@chakra-ui/react";
import { useEventHandler } from "hooks";
import { ChangeEvent, forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { styles } from "./styles";

type Props = {
    onSubmit: (value: string) => void;
    isAnswered: boolean;
    isCorrect?: boolean;
    correctAnswer?: string;
};

export interface InputMethods {
    reset: () => void;
}

export const Input = forwardRef<InputMethods, Props>(({ onSubmit, isAnswered, isCorrect, correctAnswer }, ref) => {
    const [value, setValue] = useState("");

    const isWrong = isAnswered && correctAnswer && !isCorrect;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isAnswered) return;

        setValue(event.target.value);
    };

    const enterPressHandler = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter" && !isAnswered) {
                onSubmit(value);
            }
        },
        [value, onSubmit, isAnswered]
    );

    useEventHandler("keypress", enterPressHandler);

    useImperativeHandle(ref, () => ({ reset: () => setValue("") }), []);

    return (
        <Box>
            <ChakraInput
                placeholder="Type here"
                sx={{
                    ...styles.input,
                    ...(isAnswered ? (isCorrect ? styles.correctAnswer : styles.wrongAnswer) : {}),
                }}
                value={value}
                onChange={handleChange}
            />
            <Text sx={styles.hint}>Press enter to confirm</Text>
            {isWrong && (
                <HStack spacing={2} mt={8}>
                    <CheckCircleIcon color="#3AC280" />
                    <Text sx={styles.correct}>{correctAnswer}</Text>
                </HStack>
            )}
        </Box>
    );
});
