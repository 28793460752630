import levelReachBg from "assets/images/notification/levelReachBg.png";
import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    body: {
        pt: "18px",
        pb: "46px",
        flexDirection: "column",
        alignItems: "center",
        background: `url("${levelReachBg}") 100% 100%/cover no-repeat`,
    },
    heading: {
        ...typography(20, 24, 700),
        mb: 2,
    },
    info: {
        ...typography(14, 17, 600),
        color: "gray.500",
        mb: 6,
    },
    level: {
        ...typography(64, 77, 700),
    },
};
