import { SimpleGrid } from "@chakra-ui/react";
import { FC } from "react";

import { PacksListItem } from "../types";
import { EmptyGrid } from "./EmptyGrid";
import { Item } from "./Item";

type Props = {
    packs: PacksListItem[];
    onItemClick: (id: number) => void;
};

export const PacksGrid: FC<Props> = ({ packs, onItemClick }) => {
    return packs.length ? (
        <SimpleGrid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" columnGap={14} rowGap={8}>
            {packs.map((pack) => (
                <Item {...pack} key={pack.id} onClick={onItemClick} />
            ))}
        </SimpleGrid>
    ) : (
        <EmptyGrid />
    );
};
