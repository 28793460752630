import { Button, Flex, Heading, HStack, Image, ModalBody, ModalFooter, Text } from "@chakra-ui/react";
import { useAppDispatch } from "app/store/hooks";
import { coin, rocket, thunder } from "assets/images/header/achievements";
import { showNotification } from "containers/Notification/store";
import { FC } from "react";

import { modalFooter } from "../commonStyles";
import { CommonProps } from "../types";
import { styles } from "./styles";

export const UnlockConfirmation: FC<CommonProps> = ({ onClose }) => {
    const dispatch = useAppDispatch();

    const confirm = () => {
        dispatch(showNotification({ template: "unlockSent" }));
    };

    return (
        <>
            <ModalBody as={Flex} sx={styles.body}>
                <HStack spacing={6} sx={styles.iconsContainer}>
                    <Image src={rocket} sx={styles.rocket} />
                    <Image src={thunder} sx={styles.thunder} />
                    <Image src={coin} sx={styles.coin} />
                </HStack>
                <Heading sx={styles.heading}>Unlock all functions</Heading>
                <Text sx={styles.info}>
                    Just send confirmation and <br /> we are soon connect with you
                </Text>
            </ModalBody>
            <ModalFooter as={Button} colorScheme="green" sx={modalFooter} onClick={confirm}>
                Yes, i want to unlock
            </ModalFooter>
        </>
    );
};
