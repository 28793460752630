import { FormControl, FormLabel } from "@chakra-ui/react";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { FileInput } from "./FileInput";
import { Props as FileInputProps } from "./FileInput/types";
import { Input, Props as InputProps } from "./Input";
import { Props as RadioProps, Radio } from "./Radio";
import { Props as TextareaProps, Textarea } from "./Textarea";

type InputsProps = {
    input: InputProps;
    textarea: TextareaProps;
    file: FileInputProps;
    radio: RadioProps;
};

type InputType = keyof InputsProps;

type Inputs = { [Type in InputType]: FC<InputsProps[Type]> };

const inputs: Inputs = {
    input: Input,
    textarea: Textarea,
    file: FileInput,
    radio: Radio,
};

type Props<Type extends InputType> = InputsProps[Type] & {
    label?: string;
    type: Type;
    name: string;
    isRequired?: boolean;
};

export const FormInput = <Type extends InputType>({ label, type, isRequired, ...inputProps }: Props<Type>) => {
    const { control } = useFormContext();
    const { field } = useController({
        control,
        name: inputProps.name,
        rules: { required: isRequired ? "This field is required" : undefined },
    });

    const Input = inputs[type];

    return (
        <FormControl>
            {label && <FormLabel htmlFor={inputProps.name}>{label}</FormLabel>}
            {/* @ts-ignore */}
            <Input {...inputProps} {...field} />
        </FormControl>
    );
};
