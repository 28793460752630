import { Heading } from "@chakra-ui/react";
import { FC } from "react";

import { Banners } from "./Banners";
import { GamesCards } from "./GamesCards";

export const Home: FC = () => {
    return (
        <>
            <Heading>Games</Heading>
            <GamesCards />
            <Banners />
        </>
    );
};
