import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { history } from "app";
import { Mutex } from "async-mutex";
import { logout, updateAccessToken } from "containers/Auth/slice";
import { selectRefreshToken, selectToken } from "containers/Auth/slice/selectors";

import { ROUTES } from "../routes/constants";
import { ApplicationState } from "../store";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: "https://roofat.az/api/",
    prepareHeaders: (headers, { getState }) => {
        const token = selectToken(getState() as ApplicationState);

        if (token && !headers.has("token")) {
            headers.set("token", token);
        }

        return headers;
    },
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    await mutex.waitForUnlock();
    let result = (await baseQuery(args, api, extraOptions)) as any;
    if (result.error && result.error.originalStatus === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refresh_token = selectRefreshToken(api.getState() as ApplicationState);
                const refreshResult = (await baseQuery(
                    { url: "/public/users/refresh-token", headers: { token: refresh_token } },
                    api,
                    extraOptions
                )) as any;
                if (refreshResult.data) {
                    api.dispatch(updateAccessToken(refreshResult.data));
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(logout());
                    history.push(ROUTES.AUTH.LOGIN);
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};
