import { Button, Flex, Heading, HStack, SimpleGrid, Spinner } from "@chakra-ui/react";
import capitalize from "lodash/capitalize";
import { useNavigate, useParams } from "react-router-dom";

import { useActivateMutation, useGetPackQuery } from "./api";
import { QuestionCard } from "./QuestionCard";
import { styles } from "./styles";

export const Pack = () => {
    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { data, isLoading } = useGetPackQuery(id);
    const [activate, activation] = useActivateMutation();

    if (!id) return null;

    const handleClickAdd = () => {
        if (data) {
            navigate(`/admin/quiz/${data.id}/question/create`);
        }
    };

    const handleActivateClick = () => {
        if (data) {
            activate(data.id);
        }
    };

    return (
        <>
            {isLoading && <Spinner size="xl" />}
            {data && (
                <>
                    <Flex sx={styles.header}>
                        <Heading>{capitalize(data.title)}</Heading>
                        {data.status === "DRAFT" && (
                            <HStack spacing={10}>
                                <Button
                                    colorScheme="green"
                                    variant="link"
                                    isLoading={activation.isLoading}
                                    onClick={handleActivateClick}
                                >
                                    Activate
                                </Button>
                                <Button colorScheme="green" onClick={handleClickAdd} isLoading={isLoading}>
                                    Add question
                                </Button>
                            </HStack>
                        )}
                    </Flex>
                    <SimpleGrid templateColumns="repeat(auto-fill, minmax(248px, 1fr))" columnGap={8} rowGap={6}>
                        {data.questions?.map((q) => (
                            <QuestionCard key={q.id} {...q} quiz={data} />
                        ))}
                    </SimpleGrid>
                </>
            )}
        </>
    );
};
