import { Button, ButtonProps } from "@chakra-ui/react";
import { useLogoutMutation } from "app/api";
import { FC } from "react";
import { LogoutIcon } from "theme/icons/LogoutIcon";

export const LogoutButton: FC<ButtonProps> = (props) => {
    const [logout] = useLogoutMutation();

    return (
        <Button variant="ghost" colorScheme="red" rightIcon={<LogoutIcon />} size="sm" onClick={logout} {...props}>
            Logout
        </Button>
    );
};
