import { Styles } from "types";

export const styles: Styles = {
    root: {
        minHeight: "100vh",
        backgroundColor: "gray.50",
    },
    main: {
        ml: "auto",
        width: "calc(100% - 236px)",
    },
    content: {
        pt: 8,
        px: 12,
        pb: 14,
    },
};
