import { SystemStyleObject } from "@chakra-ui/react";
import { typography } from "theme/mixins";
import { Styles } from "types";

const iconPosition: SystemStyleObject = {
    position: "absolute",
    top: 0,
    right: 0,
};

export const styles: Styles = {
    body: {
        pt: "18px",
        pb: "46px",
        flexDirection: "column",
        alignItems: "center",
    },
    heading: {
        ...typography(20, 24, 700),
        mb: 2,
    },
    answered: {
        ...typography(14, 17, 600),
        color: "gray.500",
        mb: 7,
    },
    polygonText: {
        ...typography(26, 31, 700),
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    coin: {
        ...iconPosition,
        w: 21,
        h: 22,
    },
    thunder: {
        ...iconPosition,
        w: "13px",
        h: "25px",
    },
};
