import {
    ArticleEditor,
    ArticlesPage,
    AuthLayout,
    Games,
    Home,
    Leaderboard,
    LoginForm,
    Quiz,
    RegisterForm,
    Store,
    StorePage,
    Students,
} from "containers";
import { AdminLayout } from "containers/Admin/Layout";
import { Pack } from "containers/Admin/Quiz/Pack";
import { QuestionEditor } from "containers/Admin/Quiz/QuestionEditor";
import { DashboardLayout } from "containers/Dashboard/Layout";
import { Layout } from "containers/Layout";
import { Route, Routes } from "react-router-dom";

import { ProductEditor } from "../../containers/Admin/StorePage/ProductEditor";
import { QuizFrame } from "../../containers/Dashboard/Games/ui/QuizFrame";
import { ROUTES } from "./constants";
import { PrivateRoutesContainer } from "./PrivateRoutesContainer";

export const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route path={ROUTES.AUTH.PATH} element={<AuthLayout />}>
                <Route path={ROUTES.AUTH.LOGIN} element={<LoginForm />} />
                <Route path={ROUTES.AUTH.SIGN_UP} element={<RegisterForm />} />
            </Route>
            <Route element={<PrivateRoutesContainer />}>
                <Route path={ROUTES.DASHBOARD.PATH} element={<DashboardLayout />}>
                    <Route path={ROUTES.DASHBOARD.HOME} element={<Home />} />
                    <Route path={ROUTES.DASHBOARD.LEADERBOARD} element={<Leaderboard />} />
                    <Route path={ROUTES.DASHBOARD.STORE} element={<Store />} />
                    <Route path={ROUTES.DASHBOARD.GAMES.CLASSIC_QUIZ} element={<Games />} />
                    <Route path={ROUTES.DASHBOARD.GAMES.VIDEO_QUIZ} element={<Games />} />
                    <Route path={`${ROUTES.DASHBOARD.GAMES.CLASSIC_QUIZ}/:id`} element={<QuizFrame />} />
                    <Route path={`${ROUTES.DASHBOARD.GAMES.VIDEO_QUIZ}/:id`} element={<QuizFrame />} />
                </Route>
                <Route path={ROUTES.ADMIN.PATH} element={<AdminLayout />}>
                    <Route path={ROUTES.ADMIN.BLOG.PATH} element={<ArticlesPage />} />
                    <Route path={`${ROUTES.ADMIN.BLOG.EDIT}/:id`} element={<ArticleEditor />} />
                    <Route path={ROUTES.ADMIN.BLOG.CREATE} element={<ArticleEditor />} />

                    <Route path={ROUTES.ADMIN.OUR_SOCIAL_LIFE.PATH} element={<ArticlesPage />} />
                    <Route path={`${ROUTES.ADMIN.OUR_SOCIAL_LIFE.EDIT}/:id`} element={<ArticleEditor />} />
                    <Route path={ROUTES.ADMIN.OUR_SOCIAL_LIFE.CREATE} element={<ArticleEditor />} />

                    <Route path={ROUTES.ADMIN.QUIZ.PATH} element={<Quiz />} />
                    <Route path={`${ROUTES.ADMIN.QUIZ.PACK}/:id`} element={<Pack />} />
                    <Route path={`${ROUTES.ADMIN.QUIZ.EDIT}/:questionId`} element={<QuestionEditor />} />
                    <Route path={ROUTES.ADMIN.QUIZ.CREATE} element={<QuestionEditor />} />

                    <Route path={ROUTES.ADMIN.STORE.PATH} element={<StorePage />} />
                    <Route path={ROUTES.ADMIN.STORE.CREATE} element={<ProductEditor />} />
                    <Route path={`${ROUTES.ADMIN.STORE.EDIT}/:productId`} element={<ProductEditor />} />

                    <Route path={ROUTES.ADMIN.STUDENTS.PATH} element={<Students />} />
                </Route>
            </Route>
        </Route>
    </Routes>
);
