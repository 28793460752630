import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import { styles } from "./styles";

export const DashboardLayout: FC = () => {
    return (
        <Flex sx={styles.root}>
            <Sidebar />
            <Box as="main" sx={styles.main}>
                <Header />
                <Box sx={styles.content}>
                    <Outlet />
                </Box>
            </Box>
        </Flex>
    );
};
