import { Box, Button, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { useAppDispatch } from "app/store/hooks";
import { coin } from "assets/images/header/achievements";
import { Coins } from "components/TextGradient";
import { useNavigate } from "react-router-dom";

import { requestDeletion } from "../../store/deletionSlice";
import { useDeleteGoodMutation } from "../api";
import { GoodType } from "../types";
import { styles } from "./styles";

export const Good = ({ id, price, quantity, name, file }: GoodType) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [deleteGood] = useDeleteGoodMutation();

    const goToProductEditor = () => {
        navigate(`${ROUTES.ADMIN.STORE.EDIT}/${id}`);
    };

    const handleDelete = () => {
        if (id) {
            dispatch(
                requestDeletion({
                    onConfirm: () => deleteGood(id),
                    entityType: "product",
                })
            );
        }
    };

    return (
        <Flex sx={styles.card}>
            <Box sx={styles.topSide}>
                <Flex sx={styles.price}>
                    <Flex align="center">
                        <Coins value={price} sx={styles.priceValue} />
                        <Image src={coin} sx={styles.coinIcon} />
                    </Flex>
                </Flex>
                <Flex sx={styles.quantity}>{quantity} pcs</Flex>
            </Box>
            <Image src={file.file} alt="Product image" sx={styles.image} />
            <Flex sx={styles.bottomSide}>
                <Text sx={styles.name}>{name}</Text>
                <HStack spacing={6} sx={styles.actions}>
                    <Button variant="outline" colorScheme="blue" size="sm" onClick={goToProductEditor}>
                        Edit
                    </Button>
                    <Button colorScheme="red" variant="link" size="sm" onClick={handleDelete}>
                        Delete
                    </Button>
                </HStack>
            </Flex>
        </Flex>
    );
};
