import { TextProps } from "@chakra-ui/react";
import { FC } from "react";

import { BaseTextGradient } from "./BaseTextGradient";

export const Points: FC<Record<"value", number> & TextProps> = ({ value, ...textProps }) => {
    return (
        <BaseTextGradient background="linear-gradient(270deg, #AA4CBB -24.65%, #6F6EEB 98.59%)" {...textProps}>
            {value}
        </BaseTextGradient>
    );
};
