import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    card: {
        h: 182,
        backgroundColor: "white",
        borderRadius: 12,
        boxShadow:
            "0px 24px 70px rgba(0, 0, 0, 0.09), 0px 7.2353px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 3.00517px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.08691px 3.62304px rgba(0, 0, 0, 0.0243888)",
        px: 2,
        pt: 2,
        pb: 4,
        cursor: "pointer",
    },
    background: {
        h: 130,
        borderRadius: 6,
        px: 4,
        py: 6,
    },
    NOT_STARTED: {
        background: "linear-gradient(180deg, #5B83DB 0%, #021C56 100%)",
        color: "#C3CEE6",
    },
    STARTED: {
        background: "linear-gradient(180deg, #D8E72F 0%, #D69414 100%)",
        color: "#9C7615",
    },
    FINISHED: {
        background: "linear-gradient(180deg, #5EDB5B 0%, #278A0E 100%)",
        color: "white",
    },
    title: typography(24, 33, 700),
    answered: typography(14, 19, 700),
    done: typography(13, 16, 500),
    bottomSide: {
        justifyContent: "space-between",
        px: 2,
        mt: 3,
    },
    quizInfo: {
        ...typography(13, 16, 600),
        color: "gray.500",
    },
};
