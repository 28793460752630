import { Styles } from "types";

export const styles: Styles = {
    label: {
        lineHeight: "19px",
        color: "blue.900",
        mb: 1,
    },
    error: {
        mt: 1,
    },
};
