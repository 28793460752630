import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { useAppDispatch } from "app/store/hooks";
import coinIcon from "assets/images/header/achievements/coin.png";
import { Coins } from "components/TextGradient";
import { showNotification } from "containers/Notification/store";
import { FC } from "react";
import { Product } from "types/product";

import { usePurchaseMutation } from "./api";
import { styles } from "./styles";

export const Item: FC<Product> = ({ price, name, file, id }) => {
    const [purchase, { isLoading }] = usePurchaseMutation();
    const dispatch = useAppDispatch();

    const handleClickGetNow = () => {
        purchase(id).then(() => {
            dispatch(showNotification({ template: "successfulPurchase" }));
        });
    };

    return (
        <Flex sx={styles.item}>
            <Flex sx={styles.price}>
                <Coins value={price} />
                <Image src={coinIcon} sx={styles.coinIcon} />
            </Flex>
            <Image src={file.file} w={133} margin="auto" />
            <Text sx={styles.label}>{name}</Text>
            <Button
                size="sm"
                colorScheme="gray"
                sx={styles.getButton}
                onClick={handleClickGetNow}
                isLoading={isLoading}
            >
                Get now
            </Button>
        </Flex>
    );
};
