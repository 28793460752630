import { Button, Image, Link, ModalBody, ModalFooter, Stack, Text } from "@chakra-ui/react";
import sad from "assets/images/notification/sad.png";
import { FC } from "react";

import { modalFooter } from "../commonStyles";
import { CommonProps } from "../types";
import { styles } from "./styles";

export const Looser: FC<CommonProps> = ({ onClose }) => {
    return (
        <>
            <ModalBody as={Stack} spacing={6} sx={styles.body}>
                <Image src={sad} sx={styles.emoji} />
                <Text sx={styles.info}> Bugün səninki gətirmir, bəlkə ingilis dilinə yazılasan?</Text>
                <Link href="https://roofat.az" sx={styles.info}>
                    👉 sayta link 👈
                </Link>
            </ModalBody>
            <ModalFooter as={Button} colorScheme="green" sx={modalFooter}>
                Got it!
            </ModalFooter>
        </>
    );
};
