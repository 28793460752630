import { Button, Flex, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { compose } from "@reduxjs/toolkit";
import { useRegisterMutation } from "app/api";
import { ROUTES } from "app/routes/constants";
import { FormInput } from "components/FormInput";
import { Logo } from "components/Logo";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import { formProps, requiredErrorMessage } from "./consts";
import { styles } from "./styles";
import { RegisterFormType } from "./types";
import { normalizeRegistrationData } from "./utils";

export const RegisterForm: FC = () => {
    const form = useForm<RegisterFormType>(formProps);
    const [register, { isLoading }] = useRegisterMutation();

    const validateConfirmPassword = (confirmPassword: string) => {
        if (form.getValues("password") && form.getValues("password") !== confirmPassword) {
            return "Passwords must be same";
        }
    };

    const onSubmit = form.handleSubmit(compose(register, normalizeRegistrationData));

    const formView = (
        <form onSubmit={onSubmit}>
            <Heading sx={styles.header}>Registration</Heading>
            <FormProvider {...form}>
                <Stack spacing={5} sx={styles.form}>
                    <FormInput name="name" label="Name" />
                    <FormInput name="surname" label="Surname" />
                    <FormInput label="Email" name="email" rules={{ required: requiredErrorMessage }} />
                    <FormInput
                        label="Password"
                        name="password"
                        type="password"
                        rules={{ required: requiredErrorMessage }}
                    />
                    <FormInput
                        label="Repeat password"
                        name="confirmPassword"
                        type="password"
                        rules={{ validate: validateConfirmPassword }}
                    />
                </Stack>
            </FormProvider>
            <Button colorScheme="green" isLoading={isLoading} type="submit" sx={styles.submitButton}>
                Sign up
            </Button>
        </form>
    );

    return (
        <>
            <Flex sx={styles.formContainer}>
                <Logo sx={styles.logo} />
                <Text sx={styles.welcome}>Welcome</Text>
                {formView}
            </Flex>
            <Text sx={styles.bottomLink}>
                Do you have an account?{" "}
                <Link as={RouterLink} to={ROUTES.AUTH.LOGIN}>
                    Login
                </Link>
            </Text>
        </>
    );
};
