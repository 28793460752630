import { Heading } from "@chakra-ui/react";
import { FC } from "react";

import { ItemsGrid } from "./ItemsGrid";

export const Store: FC = () => {
    return (
        <>
            <Heading>Store</Heading>
            <ItemsGrid />
        </>
    );
};
