import { Styles } from "types";

import { commonStyles } from "../commonStyles";

export const styles: Styles = {
    ...commonStyles,
    formContainer: {
        ...commonStyles.formContainer,
        maxHeight: 730,
    },
    submitButton: {
        ...commonStyles.submitButton,
        mt: "42px",
    },
    bottomLink: {
        ...commonStyles.bottomLink,
        mb: 7,
    },
};
