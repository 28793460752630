import { Button, Flex, Image, Text } from "@chakra-ui/react";
import rocket from "assets/images/quiz/rocket.png";
import { FC } from "react";

import { useStartQuizMutation } from "../../../api";
import { QUESTION_TIMEOUT } from "../consts";
import { styles } from "./styles";

type Props = {
    id: number;
};

export const InitialView: FC<Props> = ({ id }) => {
    const [startQuiz, { isLoading }] = useStartQuizMutation();

    const handleStart = () => {
        startQuiz(id);
    };

    return (
        <Flex sx={styles.root}>
            <Image src={rocket} sx={styles.image} />
            <Text sx={styles.info}>
                You have {QUESTION_TIMEOUT} seconds for each answer
                <br />3 correct answers in a row gives bonuses
            </Text>
            <Button onClick={handleStart} colorScheme="green" size="md" sx={styles.startButton} isLoading={isLoading}>
                Start
            </Button>
        </Flex>
    );
};
