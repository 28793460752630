import { Box } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
    color: "yellow" | "violet";
};

const gradients = {
    yellow: "paint0_linear_259_3103",
    violet: "paint0_linear_259_3099",
};

export const Polygon: FC<Props> = ({ color, children }) => {
    return (
        <Box position="relative">
            <svg width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M28 1.3094C30.4752 -0.119661 33.5248 -0.119661 36 1.3094L59.1769 14.6906C61.6521 16.1197 63.1769 18.7607 63.1769 21.6188V48.3812C63.1769 51.2393 61.6521 53.8803 59.1769 55.3094L36 68.6906C33.5248 70.1197 30.4752 70.1197 28 68.6906L4.82308 55.3094C2.34788 53.8803 0.823086 51.2393 0.823086 48.3812V21.6188C0.823086 18.7607 2.34788 16.1197 4.82309 14.6906L28 1.3094Z"
                    fillOpacity="0.3"
                    fill={`url(#${gradients[color]})`}
                />
                <defs>
                    <linearGradient
                        id={gradients.yellow}
                        x1="32"
                        y1="-1"
                        x2="32"
                        y2="71"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FED325" stopOpacity="0.4" />
                        <stop offset="1" stopColor="#FED325" />
                    </linearGradient>
                    <linearGradient
                        id={gradients.violet}
                        x1="32"
                        y1="-1"
                        x2="32"
                        y2="71"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AA4CBB" stopOpacity="0.3" />
                        <stop offset="1" stopColor="#AA4CBB" />
                    </linearGradient>
                </defs>
            </svg>
            {children}
        </Box>
    );
};
