import { Button, Flex, Heading, HStack, Image, ModalBody, ModalFooter, Text } from "@chakra-ui/react";
import { coin, thunder } from "assets/images/header/achievements";
import { ReactComponent as MedalIcon } from "assets/images/notification/medal.svg";
import { Polygon } from "components/Polygon";
import { Coins, Points } from "components/TextGradient";
import { FC } from "react";

import { modalFooter } from "../commonStyles";
import { CommonProps } from "../types";
import { styles } from "./styles";

type Props = CommonProps & {
    answeredCount: number;
    questionCount: number;
    coins: number;
    points: number;
};

export const TrialTimeOut: FC<Props> = ({ answeredCount, questionCount, coins, points, onClose }) => {
    return (
        <>
            <ModalBody as={Flex} sx={styles.body}>
                <MedalIcon />
                <Heading sx={styles.heading}>Your trial time is end</Heading>
                <Text sx={styles.answered}>
                    Answered {answeredCount} of {questionCount}
                </Text>
                <HStack spacing={8}>
                    <Polygon color="yellow">
                        <Coins value={coins} sx={styles.polygonText} />
                        <Image src={coin} sx={styles.coin} />
                    </Polygon>
                    <Polygon color="violet">
                        <Points value={points} sx={styles.polygonText} />
                        <Image src={thunder} sx={styles.thunder} />
                    </Polygon>
                </HStack>
            </ModalBody>
            <ModalFooter as={Button} colorScheme="green" sx={modalFooter} onClick={onClose}>
                Got it!
            </ModalFooter>
        </>
    );
};
