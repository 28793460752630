import { Stack, useRadioGroup } from "@chakra-ui/react";
import { FC } from "react";
import { AnswerForUser } from "types/question";

import { RadioOption } from "./RadioOption";

type Props = {
    options: Array<AnswerForUser>;
    correct?: string;
    onChange: (answer: string) => void;
    isAnswered: boolean;
    disabled?: boolean;
    questionId?: number;
};

export const Options: FC<Props> = ({ options, correct, onChange, isAnswered, disabled, questionId }) => {
    const { getRootProps, getRadioProps } = useRadioGroup({ name: "answer", onChange });

    return (
        <Stack spacing={4} {...getRootProps()}>
            {options.map(({ body }) => (
                <RadioOption
                    key={`${questionId}_${body}`}
                    isTrue={correct === body}
                    label={body}
                    isAnswered={isAnswered}
                    disabled={disabled}
                    {...getRadioProps({ value: body })}
                />
            ))}
        </Stack>
    );
};
