import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    card: {
        borderRadius: 8,
        border: "1px solid #E4E4E4",
        bgColor: "white",
        height: 285,
        justifyContent: "space-between",
        flexDirection: "column",
    },
    topSide: {
        display: "flex",
        justifyContent: "space-between",
    },
    price: {
        width: 92,
        borderRadius: "8px 0px 50px",
        bgColor: "blue.900",
        alignItems: "center",
        justifyContent: "center",
    },
    priceValue: typography(16, 14, 700),
    quantity: {
        ...typography(14, 17, 500),
        borderRadius: 40,
        bgColor: "gray.200",
        h: 25,
        w: 85,
        alignItems: "center",
        justifyContent: "center",
        mt: "6px",
        mr: 4,
    },
    coinIcon: {
        ml: 1,
        w: "14px",
        h: "14px",
    },
    name: {
        ...typography(14, 17, 500),
        mx: 4,
        mb: 4,
        color: "blue.900",
    },
    actions: {
        borderTop: "1px solid rgba(146, 154, 182, 0.1)",
        justifyContent: "flex-end",
        px: 4,
        py: 2,
    },
    image: {
        maxHeight: 140,
        objectFit: "contain",
    },
    bottomSide: {
        height: 100,
        flexDirection: "column",
        justifyContent: "flex-end",
    },
};
