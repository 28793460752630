import { api } from "app/api";

import { LeaderBoardResponse } from "./types";

export const injectedLeaderboardEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getLeaderBoard: builder.query<LeaderBoardResponse, void>({
            query: () => `users/leaderboard`,
        }),
    }),
});

export const { useGetLeaderBoardQuery } = injectedLeaderboardEndpoints;
