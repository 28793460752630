import { Box, Flex, Image } from "@chakra-ui/react";
import leftSideBackground from "assets/images/auth/loginBackground.png";

import { styles } from "./styles";

export const LeftSide = () => (
    <Flex sx={styles.overlay}>
        <Box sx={styles.background} />
        <Image sx={styles.image} src={leftSideBackground} />
    </Flex>
);
