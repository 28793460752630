import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    option: {
        border: "1px solid #D7D7D7",
        backgroundColor: "#F6F7F9",
        borderRadius: 4,
        pt: "10px",
        pb: "11px",
        px: 3,
        cursor: "pointer",

        "& .chakra-radio__label": typography(14, 17, 500),

        "& label": {
            display: "flex",
            width: "max-content",
        },
    },
    correctOption: {
        backgroundColor: "rgba(58, 194, 128, 0.1)",
        borderColor: "#3AC280",
    },
    wrongOption: {
        backgroundColor: "rgba(229, 122, 122, 0.1)",
        borderColor: "#E67A7A",
    },
};
