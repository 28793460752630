import { Button, Heading, HStack, SimpleGrid, Spinner } from "@chakra-ui/react";
import { ROUTES } from "app/routes/constants";
import { useNavigate } from "react-router-dom";

import { useGetGoodsQuery } from "./api";
import { Good } from "./Good";

export const StorePage = () => {
    const { data, isLoading } = useGetGoodsQuery();
    const navigate = useNavigate();

    const addProduct = () => {
        navigate(ROUTES.ADMIN.STORE.CREATE);
    };

    return (
        <>
            <HStack justify="space-between" mb={7}>
                <Heading>Store products</Heading>
                <Button colorScheme="green" onClick={addProduct}>
                    Add product
                </Button>
            </HStack>
            <SimpleGrid templateColumns="repeat(auto-fill, minmax(248px, 1fr))" gap={8}>
                {isLoading && <Spinner size="xl" />}
                {data && data.map((good) => <Good {...good} key={good.id} />)}
            </SimpleGrid>
        </>
    );
};
