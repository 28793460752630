import { Box, Flex, Heading, HStack } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { QuestionForUser } from "types/question";

import { CheckAnswerRequest } from "../../../types";
import { Achievements } from "./Achievements";
import { Input, InputMethods } from "./Input";
import { Options } from "./Options";
import { Presentation } from "./Presentation";
import { styles } from "./styles";
// import { Methods, Timer } from "./Timer";

type Props = QuestionForUser & {
    isAnswered: boolean;
    quizId: number;
    checkAnswer: (requestBody: CheckAnswerRequest) => Promise<void>;
    isChecking?: boolean;
    isCorrect?: boolean;
    correctAnswer?: string;
    achievedPoints?: number;
    achievedCoins?: number;
};

export const Question: FC<Props> = ({
    Question,
    isAnswered,
    isCorrect,
    quizId,
    checkAnswer,
    isChecking,
    correctAnswer,
    achievedCoins = 0,
    achievedPoints = 0,
}) => {
    // const timerRef = useRef<Methods>(null);
    const inputRef = useRef<InputMethods>(null);
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const { body, answers, file, id } = Question;

    useEffect(() => {
        // timerRef.current?.run();
        inputRef.current?.reset();
    }, [id]);

    if (!Question) {
        return null;
    }

    const handleAnswer = (answer: string) => {
        if (!id) return;

        // timerRef.current?.pause();

        setSelectedAnswer(answer);

        const requestBody: CheckAnswerRequest = {
            QuestionId: id,
            QuizId: quizId,
            Answer: answer,
            isSkipped: false,
        };

        checkAnswer(requestBody);
    };

    // const handleTimeOut = () => {
    //     if (!id) return;
    //
    //     timerRef.current?.pause();
    //
    //     const requestBody: CheckAnswerRequest = {
    //         QuestionId: id,
    //         QuizId: quizId,
    //         Answer: "",
    //         isSkipped: true,
    //     };
    //
    //     checkAnswer(requestBody).then(timerRef.current?.run);
    // };

    return (
        <Box>
            <Flex sx={styles.heading}>
                <Heading sx={styles.question}>{body}</Heading>
                <HStack spacing={8}>
                    <Achievements points={achievedPoints} coins={achievedCoins} />
                    {/*<Timer onTimeOut={handleTimeOut} ref={timerRef} />*/}
                </HStack>
            </Flex>
            <Flex justify="space-between">
                <Box sx={styles.answerContainer}>
                    {answers && answers.length > 1 ? (
                        <Options
                            questionId={Question?.id}
                            options={answers}
                            correct={correctAnswer || (isCorrect ? selectedAnswer : "")}
                            onChange={handleAnswer}
                            isAnswered={isAnswered}
                            disabled={isChecking}
                        />
                    ) : (
                        <Input
                            ref={inputRef}
                            isCorrect={isCorrect}
                            onSubmit={handleAnswer}
                            correctAnswer={correctAnswer}
                            isAnswered={isAnswered}
                        />
                    )}
                </Box>
                {file && <Presentation {...file} />}
            </Flex>
        </Box>
    );
};
