import { typography } from "../mixins";

export const Text = {
    baseStyle: {
        ...typography([15, 18], [22, 26], 500),
    },
    sizes: {
        sm: {
            ...typography(14, 24, 500),
        },
    },
    variants: {
        card: {
            color: "gray.400",
        },
    },
};
