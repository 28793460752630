import { Button } from "./Button";
import { FormLabel } from "./FormLabel";
import { Heading } from "./Heading";
import { HiddenLink } from "./HiddenLink";
import { Text } from "./Text";

export const components = {
    Button,
    Heading,
    Text,
    HiddenLink,
    FormLabel,
};
