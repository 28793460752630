import { Avatar, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Level } from "components/TextGradient";

import { useGetStudentsQuery } from "../api";
import { RoleSwitcher } from "./RoleSwitcher";
import { styles } from "./styles";

type Props = {
    searchKeyword?: string;
};

export const StudentsTable = ({ searchKeyword }: Props) => {
    const { data } = useGetStudentsQuery(searchKeyword);

    return (
        <Table size="md" colorScheme="blackAlpha" sx={styles.root}>
            <Thead sx={styles.thead}>
                <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Level</Th>
                    <Th width="50%" textAlign="right">
                        Change status
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {data?.map(({ name, level, role, id, email }) => (
                    <Tr sx={styles.row} key={id}>
                        <Td>
                            <Flex align="center">
                                <Avatar name={name} sx={styles.avatar} />
                                <Text sx={styles.name}>{name}</Text>
                            </Flex>
                        </Td>
                        <Td>{email}</Td>
                        <Td>
                            <Level value={level} />
                        </Td>
                        <Td>
                            <RoleSwitcher id={id} role={role} />
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
