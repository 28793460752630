import { HStack, Radio, RadioGroup } from "@chakra-ui/react";
import { UserRoleType } from "types/user";

import { useChangeRoleMutation } from "../api";

type Props = {
    role: UserRoleType;
    id: number;
};

export const RoleSwitcher = ({ role, id }: Props) => {
    const [changeRole] = useChangeRoleMutation();

    const handleChange = (role: UserRoleType) => {
        changeRole({ role, id });
    };

    return (
        <HStack as={RadioGroup} spacing={4} justify="flex-end" value={role} name="role" onChange={handleChange as any}>
            <Radio bg="white" colorScheme="green" value="ROOF_STUDENT">
                Student
            </Radio>
            <Radio bg="white" colorScheme="green" value="FREE_USER">
                Non student
            </Radio>
        </HStack>
    );
};
