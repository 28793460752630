import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    root: {
        pl: 2,
        pr: 5,
        py: 1,
        border: "1px solid #EEE",
        borderRadius: 60,
        backgroundColor: "white",
        alignItems: "center",
    },
    info: {
        ml: 3,
    },
    goal: {
        ...typography(10, 12, 500),
    },
    shouldDo: {
        ...typography(10, 12, 700),
        mt: "2px",
    },
};
