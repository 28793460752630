import { SystemStyleObject } from "@chakra-ui/react";

export const modalFooter: SystemStyleObject = {
    fontSize: "15px !important",
    lineHeight: "18px !important",
    fontWeight: 600,
    justifyContent: "center",
    h: "54px",
    borderTopRadius: 0,
};
