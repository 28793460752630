import { Paragraph } from "../types";

export const getShortDescription = (paragraph: Paragraph) => {
    return paragraph.text.slice(0, 200);
};

export const getDate = (_date: string): string => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const date = new Date(_date);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
};
