import { Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { FormInput } from "containers/Admin/components/FormInput";
import capitalize from "lodash/capitalize";
import { toWordsOrdinal } from "number-to-words";
import { FC } from "react";

import { Paragraph } from "../../../Posts/types";

type Props = {
    index: number;
    remove: (index: number) => void;
};

export const Block: FC<Props> = ({ index, remove }) => {
    const handleRemove = () => {
        remove(index);
    };

    const getName = (key: keyof Paragraph) => `paragraphs.${index}.${key}`;

    return (
        <Stack spacing={6}>
            {index > 0 && (
                <HStack justify="space-between">
                    <Heading>{capitalize(toWordsOrdinal(index + 1))} block</Heading>
                    <Button onClick={handleRemove} variant="link" colorScheme="red" size="sm">
                        Delete block
                    </Button>
                </HStack>
            )}
            <FormInput label="First paragraph" type="textarea" name={getName("text")} />
            <FormInput label="First line photos (max 3)" type="file" name={getName("images")} multiple />
        </Stack>
    );
};
