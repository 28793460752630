import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { ReactComponent as DoneIcon } from "assets/images/quiz/done.svg";
import { selectIsAdmin } from "containers/Auth/slice/selectors";
import { FC, MouseEvent } from "react";

import { requestDeletion } from "../../../store/deletionSlice";
import { useDeletePackMutation } from "../../api";
import { useActivateMutation } from "../../Pack/api";
import { PackTypeForUser } from "../../types";
import { styles } from "./styles";

type Props = PackTypeForUser & {
    onClick: (id: number) => void;
};

export const Item: FC<Props> = ({
    questionCount,
    title,
    answeredCount,
    id,
    onClick,
    status,
    quizStatus = "NOT_STARTED",
}) => {
    const isAdmin = useAppSelector(selectIsAdmin);
    const dispatch = useAppDispatch();
    const [deletePack] = useDeletePackMutation();
    const [activate, activation] = useActivateMutation();

    const handleClick = () => {
        if (quizStatus !== "FINISHED") {
            onClick(id);
        }
    };

    const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(requestDeletion({ onConfirm: () => deletePack(id), entityType: "pack" }));
    };

    const handleClickActivate = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        activate(id);
    };

    return (
        <Box sx={styles.card} onClick={handleClick}>
            <Box sx={{ ...styles.background, ...styles[quizStatus ?? "NOT_STARTED"] }}>
                <Text sx={styles.title}>{title}</Text>
                {["STARTED", "FINISHED"].includes(quizStatus) && (
                    <Text sx={styles.answered}>
                        Answered {answeredCount || (quizStatus === "FINISHED" ? questionCount : 0)}/{questionCount}
                    </Text>
                )}
                {quizStatus === "FINISHED" && (
                    <HStack spacing={2} mt={2}>
                        <Text sx={styles.done}>Done</Text>
                        <DoneIcon />
                    </HStack>
                )}
            </Box>
            <HStack sx={styles.bottomSide}>
                <Text sx={styles.quizInfo}>
                    {questionCount && <>{questionCount} question</>}
                    {!isAdmin && <> • 30 sec for each</>}
                </Text>
                <HStack spacing={4}>
                    {status && status === "DRAFT" && (
                        <Button
                            colorScheme="green"
                            variant="link"
                            onClick={handleClickActivate}
                            isLoading={activation.isLoading}
                            size="md"
                        >
                            Activate
                        </Button>
                    )}
                    {isAdmin && (
                        <Button onClick={handleDelete} colorScheme="red" variant="link" size="md">
                            Delete
                        </Button>
                    )}
                </HStack>
            </HStack>
        </Box>
    );
};
