import waves from "assets/images/auth/waves.png";
import { Styles } from "types";

export const styles: Styles = {
    overlay: {
        backgroundColor: "gray.50",
        position: "relative",
        alignItems: "flex-end",
        height: "100%",
    },
    background: {
        position: "absolute",
        zIndex: "1",
        inset: "0",
        background: `url('${waves}') 100% 100%/contain no-repeat`,
        mixBlendMode: "soft-light",
    },
    image: {
        position: "relative",
        zIndex: "2",
        mixBlendMode: "normal",
        width: "90%",
    },
};
