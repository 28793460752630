import { History } from "history";
import { FC, useLayoutEffect, useState } from "react";
import { Router as ReactRouter } from "react-router-dom";

export const Router: FC<Record<"history", History>> = ({ history, ...props }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return <ReactRouter {...props} location={state.location} navigationType={state.action} navigator={history} />;
};
