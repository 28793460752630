import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    root: {
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        w: 163,
        h: 148,
    },
    info: {
        ...typography(14, 20, 600),
        color: "gray.500",
        textAlign: "center",
        mt: 5,
        mb: 10,
    },
    startButton: {
        w: 110,
    },
};
