import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
} from "@chakra-ui/react";
import { compose } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { FC, useEffect } from "react";

import { getDeletionBranch, resetDeletion } from "../store/deletionSlice";
import { styles } from "./styles";

export const DeleteConfirmationModal: FC = () => {
    const toast = useToast();
    const { onConfirm, entityType, status } = useAppSelector(getDeletionBranch);
    const dispatch = useAppDispatch();

    const cancel = compose(dispatch, resetDeletion);

    useEffect(() => {
        if (status === QueryStatus.fulfilled) {
            toast({ title: "Successfully deleted", status: "success", duration: 3000, isClosable: true });
        }
    }, [status]);

    return (
        <Modal isOpen={Boolean(entityType)} onClose={cancel} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading sx={styles.heading}>Are you sure to delete?</Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody sx={styles.body}>
                    <Text sx={styles.content}>If you agree, the {entityType} will be deleted permanently</Text>
                </ModalBody>
                <ModalFooter>
                    <HStack spacing={8}>
                        <Button variant="link" colorScheme="blue" size="sm" color="blue.900" onClick={cancel}>
                            No, cancel
                        </Button>
                        <Button
                            onClick={onConfirm}
                            isLoading={status === QueryStatus.pending}
                            colorScheme="red"
                            size="md"
                        >
                            Yes, delete
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
