import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";
import { GamesCardType } from "./types";

export const Card: FC<GamesCardType> = ({
    header,
    illustration,
    description,
    questionDuration,
    questionsCount,
    url,
}) => {
    const navigate = useNavigate();

    return (
        <Box sx={styles.card} onClick={url ? () => navigate(url) : undefined}>
            <Box sx={styles.header} background={header.background} color={header.color}>
                {header.label}
                {illustration}
            </Box>
            <Box sx={styles.info}>
                <Text sx={styles.questionInfo}>
                    {questionsCount} question • {questionDuration} sec for each
                </Text>
                <Text sx={styles.description}>{description}</Text>
            </Box>
        </Box>
    );
};
