import { typography } from "../mixins";

export const Heading = {
    defaultProps: {
        variant: "page",
    },
    baseStyle: {
        ...typography(24, 29, 700),
        color: "blue.900",
    },
    variants: {
        page: typography(26, 31, 700),
        section: typography(24, 29, 700),
        subsection: typography(20, 24, 700),
    },
};
