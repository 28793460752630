import { typography } from "theme/mixins";
import { Styles } from "types";

export const styles: Styles = {
    container: {
        pt: 110,
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
    },
    heading: {
        ...typography(32, 39, 700),
    },
    hint: {
        ...typography(20, 24, 500),
        mt: "14px",
        color: "blue.900",
    },
};
