import { Box, Flex } from "@chakra-ui/react";

import { LeftSide } from "./blocks/LeftSide";
import { RightSide } from "./blocks/RightSide";
import { styles } from "./styles";

export const AuthLayout = () => (
    <Flex sx={styles.root}>
        <Box sx={styles.leftSideContainer}>
            <LeftSide />
        </Box>
        <Box sx={styles.rightSideContainer}>
            <RightSide />
        </Box>
    </Flex>
);
