import { ROUTES } from "app/routes/constants";
import { useAppSelector } from "app/store/hooks";
import { selectUser } from "containers/Auth/slice/selectors";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { getDefaultRoute } from "./utils";

export const Layout = () => {
    const user = useAppSelector(selectUser);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const { pathname } = location;
        let authorizedPath = ROUTES.AUTH.LOGIN;

        if (user) {
            authorizedPath = ["/", ROUTES.AUTH.LOGIN].includes(pathname) ? getDefaultRoute(user?.role) : pathname;
        }
        navigate({ ...location, pathname: authorizedPath });
    }, []);

    return <Outlet />;
};
